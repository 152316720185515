/* Footer */
footer {
  .top,
  .bottom,
  .extra { max-width: $maxwidth; margin: 0 auto; }
  
  
  &, a { color: $white; }
  a:hover { text-decoration: underline; }
  
  li { list-style: none; }
  
  .top {
    margin-top: $ono * 79;
    display: flex;
    
    @include desktop { 
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: $baseline;
    }
    
    @include mobile {
      padding: 0 $space/2;
      flex-direction: column;
    }
    
    img { height: $ono * 49; }
    
  }
  
  .logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    .grown-from { height: $ono * 83; 
      @include mobile { margin-bottom: $space; }
    }
  }
  
  .socialbox {
    color: $highlight1;
    
    @include mobile { margin-bottom: $space/2; }
    
    .contacts { 
      font-size: $ono * 19;
      line-height: $ono * 25; 
      margin-bottom: $ono * 32;
      
      a { 
        color: $highlight1; 
        text-decoration: underline;
        line-height: $ono * 25; 
      }
    }
    
    .social li { display: inline-block; margin-right: $baseline/2; }
    .social a { 
      //@include transition;
//       background: $highlight1; color: $background; 
      transition: 0s ease-in all, .125s ease-in background-color;
      border-radius: 100%; 
      width: $space; height: $space; display: block;
      text-indent: -100vw; overflow: hidden;
      
      &:hover { 
        //background: $white; 
        text-decoration: none;
      }
    }
  }
  
  .menu { 
    padding-bottom: $space/2; 
    li { 
      font-size: $ono * 16; 
      line-height: $ono * 32;
    }
  }
  
  .extra { 
    font-size: $ono * 14;
    padding: ($baseline/2) 0;
    @include mobile { padding: 0 $space/2; }
    p:first-child { padding-bottom: $baseline/4; }
  }
  
  .bottom { 
    padding: $baseline 0; 
    font-size: $ono * 12; 
    
    .dicky { 
      @include transition; 
      opacity: .8; 
      &:hover { opacity: 1; 
        a { text-decoration: underline; }
      }
    }
    
    @include desktop { 
      margin-bottom: ($space*3);
      .dicky { float: right; }
    }
    
    @include mobile { 
      padding: $baseline $space/2 $space;
      margin-bottom: ($space*1.5);
      .dicky { display: block; 
        margin: $baseline 0 (-$baseline*2);  
        a { display: block; }
      }
    }
    div{
      display: inline-block;
    }
    p,a {
      display: inline-block;
      padding-right: $baseline;
    }
  }
}
