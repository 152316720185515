.form-component {
    .form-row {
        width: 100%;
        display: flex;
        gap: $space/2;
        margin: $space/2 0;
        flex: 1;
        &.no-margin{
          margin: 0;
        }
        &.hidden{
          display: none;
        }
        .hidden{
          display: none;
      }
        
    }
    .form-row > div{
        flex: 1;
    }

    .form-label{
      color: $background;
      font-weight: 700;
    }
    .radio-group-wrapper {
        display: flex;
        flex-direction: column;
        gap: $space/2;
    }

    .form-validation-error {
        padding: 1rem;
        margin-bottom: 1rem;
        background-color: #FEE2E2;
        color: rgb(239 68 68);
        border: 1px solid rgb(239 68 68);
        border-radius: 1rem;
    }

    .form-success-message {
        padding: 1rem;
        margin-bottom: 1rem;
        background-color: rgb(220 252 231);
        border: 1px solidrgb(74 222 128);
        border-radius: 1rem;
        color: rgb(21, 128, 61);
    }

    .form-field-error {
        display: none;
        color: rgb(239 68 68);
        padding: 4px 0 0;
    }

    .form-row:not(:first-child) p {
        padding-top: $baseline;
      }
      
      .checkbox-label,
      .form-text p {
        color: $background; 
      }
      
      
      .form-text p {
        font-size: $ono * 14; 
        font-weight: bold;
        
        em { 
          font-weight: normal; 
          font-size: $ono * 11;
          color: $black; 
          font-style: normal;
        }
      }

      .page-indicator{
        color: $background;
      }
      .buttons-wrapper{
        display: flex;
        gap: $space;
      }

}


.grecaptcha-badge {
    display: none !important;
}