.accordion-component {
    padding: $baseline 0;
    color: $highlight3;
    
    h4 { font-family: 'base', sans-serif; }
    h2 { 
      margin-bottom: $space; 
      @include mobile {
        margin: $space/2; 
      } 
    }

    ul { list-style: none; 
      @include mobile { padding: $space/2 0; }
    }
    
    .clicker { cursor: pointer; top: -$baseline; right: -($baseline*2); bottom: -$baseline; left: -($baseline*2); }

    .accordion-item {
        @include desktop {
          padding: $baseline $baseline*2; 
        }
        @include mobile {
          padding: $baseline $space/2; 
        }
        
        &, * { @include transition; }

        .accordion-head {
          position: relative;
        }
        
        &.accordion-item-closed {
          &:not(:first-child) {
            @include desktop { border-top: ($ono*2) $background solid; }
            @include mobile { border-top: ($ono) $background solid; }
          }
          
          background: rgba(255,255,255,.0);
          border-radius: 0;
          
          .accordion-body { max-height: 0; opacity: 0; overflow: hidden; }
        }
        
        &:hover:not(.accordion-item-open) h3 { 
            margin-left: -($baseline/2);
        }
        
        &.accordion-item-open,
        &:hover {
          &:not(:first-child),
          & + .accordion-item-closed {
             border-top: ($ono*2) solid transparent;
              @include desktop { border-top: ($ono*2) solid transparent; }
              @include mobile { border-top: ($ono) solid transparent; }
          }
          
          background: $white;
          
          @include desktop {
            border-radius: $radius;
          }
        }
      
        
        &.accordion-item-open {
          @include desktop {
            margin: $baseline/2 0;
          }
          
          .accordion-body {
            max-height: 1000vh;
            padding: $baseline 0 0 0;
          }
        }
          
        .accordion-body .accordion-content, .accordion-body .schedule-body{
          > p{
            margin-top:$space ;
            color: black;
          }
          
          .table {
            margin-top: $space;
            @include mobile {
              overflow-x: scroll;
              margin-right: -($baseline/2);
              &:not(:has(td + td)){
                overflow-x: inherit;
                margin-right: 0; 
                &::after { width: 0!important; }
                td { width: 100%; white-space: break-spaces!important; }
              }
              &::after {
                content: ''; display: block;
                width: $baseline; height: 100%;
                position: absolute;
                top: 0; right: -($baseline/2); bottom: 0; 
                
                background: 
                linear-gradient(0deg, $white 0%, rgba(255,255,255,0) 5%, rgba(255,255,255,0) 90%, $white 100%),
                  linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(0,0,0,.1) 10%, rgba(255,255,255,0) 50%),
                  linear-gradient(270deg, $white 0%, rgba(255,255,255,0) 100%);
              }
            }
            
          }
          
          position: relative;
          
          table {
            min-width: min-content; 
            border-collapse: collapse;
            width: 100%; 
            
            @include mobile { 
              margin-right: $baseline;
            }
            
            thead tr th{
              color: black;
              text-align: left;
              p{
                text-align: left;
              }
            }
            
            tbody tr {
              &:not(:first-child){
                border-top: solid $ono $highlight1;
              }
      
              td {
                table-layout: fixed;
                padding: $space 0;
                vertical-align: top;
                @include desktop { max-width: 50%; }
                @include mobile { 
                  min-width: 11rem;
                  width: min-content;
                }
                
                &:not(:last-child){
                  padding-right: $space;
                }
                &:first-child:not(:last-child){
                  white-space: nowrap;
                }
                strong{
                  white-space: nowrap;
                }
                &:not(:has(strong)){
                  color: black;
                }
              }
            }
          }
        }
          
        &.accordion-toggle {
          
        }

    }  
}

.accordion-toggle { 
  position: absolute;
  top: -$baseline/8; right: 0;
  width: $baseline; height: $baseline;
  border-radius: 100%;
  text-indent: -100vw; overflow: hidden;
  
  background: $background $arrow no-repeat center center/($ono*8); color: $highlight2;
}

.accordion-item-closed .accordion-toggle { }

.accordion-item-closed:hover .accordion-toggle { transform: rotate(90deg); }

.accordion-item-open .accordion-toggle { transform: rotate(180deg); opacity: .5; }


