body[site="global"] {
    [social] {  background: no-repeat center center/contain; }
    [social="facebook"]  { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23B8B43C;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M16.2,11.7L16,13.5c0,0.2-0.2,0.3-0.3,0.3h-1.9v5.4c0,0.1-0.1,0.2-0.2,0.2h-1.9c-0.1,0-0.2-0.1-0.2-0.2v-5.4H10c-0.2,0-0.3-0.2-0.3-0.3v-1.8c0-0.2,0.2-0.3,0.3-0.3h1.4V9.6c0-2,1.2-3.1,3-3.1h1.4c0.2,0,0.3,0.2,0.3,0.3v1.5c0,0.2-0.2,0.3-0.3,0.3h-0.9c-1,0-1.1,0.5-1.1,1.2v1.5h2.1C16.1,11.3,16.2,11.5,16.2,11.7L16.2,11.7L16.2,11.7z"/></svg>');}
    [social="linkedin"]  { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23B8B43C;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M9.7,19c0,0.2-0.2,0.4-0.4,0.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-7.8c0-0.2,0.2-0.4,0.4-0.4h1.9c0.2,0,0.4,0.2,0.4,0.4V19H9.7z M10,8.2c0,1-0.8,1.8-1.8,1.8S6.5,9.2,6.5,8.2s0.8-1.8,1.8-1.8C9.3,6.5,10,7.3,10,8.2L10,8.2z M19,19.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-3.7c0-0.5,0.2-2.4-1.4-2.4c-1.2,0-1.5,1.3-1.5,1.8V19c0,0.2-0.2,0.4-0.4,0.4h-1.9c-0.2,0-0.4-0.2-0.4-0.4v-7.9c0-0.2,0.2-0.4,0.4-0.4h1.9c0.2,0,0.4,0.2,0.4,0.4v0.7c0.5-0.7,1.1-1.2,2.6-1.2c3.2,0,3.2,3,3.2,4.6V19l0,0C19.4,19.2,19.2,19.4,19,19.4z"/></svg>'); }
    [social="youtube"]   { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23B8B43C;}</style><g><polygon class="st0" points="11.4,15.4 15.4,12.9 11.4,10.5 "/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M19.1,16.1c-0.3,0.8-1.1,1.3-1.9,1.4c-2.8,0.3-5.7,0.3-8.5,0c-0.8-0.1-1.6-0.6-1.9-1.4c-0.4-2.1-0.4-4.2,0-6.2C7,9,7.9,8.6,8.7,8.4c2.8-0.3,5.7-0.3,8.5,0C18,8.6,18.9,9,19.1,9.8C19.5,11.9,19.5,14,19.1,16.1z"/></g></svg>');}
    [social="instagram"] { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23B8B43C;}</style><g><path class="st0" d="M15.6,7.8h-5.2c-1.4,0-2.6,1.1-2.6,2.6v5.2c0,1.4,1.1,2.6,2.6,2.6h5.2c1.4,0,2.6-1.1,2.6-2.6v-5.2C18.1,8.9,17,7.8,15.6,7.8z M12.9,16.3c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S14.8,16.3,12.9,16.3z M16.3,10.4c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8C17.1,10.1,16.8,10.4,16.3,10.4z"/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M19.4,15.6c0,2.1-1.7,3.9-3.9,3.9h-5.2c-2.1,0-3.9-1.7-3.9-3.9v-5.2c0-2.1,1.7-3.9,3.9-3.9h5.2c2.1,0,3.9,1.7,3.9,3.9V15.6z"/><path class="st0" d="M12.9,10.9c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2C15,11.8,14.1,10.9,12.9,10.9z"/></g></svg>');}
    [social="x"]         { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23B8B43C;}</style><g><polygon class="st0" points="13.1,11.8 9.6,6.9 7.8,6.9 12.1,13 12.6,13.8 12.6,13.8 16.3,19 18.1,19 13.6,12.6 "/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M15.7,19.9L12,14.5l-4.6,5.4H6.2l5.3-6.2L6.2,6h4l3.5,5.1L18.1,6h1.2l-5.1,5.9l0,0l5.5,8H15.7z"/></g></svg>');}
    [social="link"]      { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23B8B43C;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M17.6,16.6c-0.3,0.3-0.7,0.7-1,1c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.2-0.4-0.3l-3.1-3.1l0,0v0.1c-0.3,0.8-0.6,1.5-1,2.3c-0.1,0.2-0.3,0.4-0.5,0.3c-0.2,0-0.4-0.2-0.4-0.4c-0.4-1.3-0.8-2.6-1.2-3.9c-0.6-1.3-1-2.5-1.4-3.8C8.1,8.6,8.1,8.4,8.3,8.3c0.1-0.1,0.2-0.1,0.3-0.1h0.2c0.8,0.3,1.5,0.5,2.2,0.7c1.8,0.6,3.7,1.2,5.5,1.7c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.3,0.2,0.3,0.4s-0.1,0.4-0.3,0.5c-0.4,0.2-0.8,0.4-1.2,0.5c-0.4,0.2-0.7,0.3-1.1,0.5c0,0,0,0-0.1,0c0,0,0,0,0.1,0.1c1,1,2.1,2.1,3.1,3.1l0.1,0.1C17.8,16.1,17.8,16.4,17.6,16.6L17.6,16.6z"/></svg>'); }

}
body[site="accelerate"] {   
    [social] {  background: no-repeat center center/contain; }
    [social="facebook"]  { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%238ca2d0;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M16.2,11.7L16,13.5c0,0.2-0.2,0.3-0.3,0.3h-1.9v5.4c0,0.1-0.1,0.2-0.2,0.2h-1.9c-0.1,0-0.2-0.1-0.2-0.2v-5.4H10c-0.2,0-0.3-0.2-0.3-0.3v-1.8c0-0.2,0.2-0.3,0.3-0.3h1.4V9.6c0-2,1.2-3.1,3-3.1h1.4c0.2,0,0.3,0.2,0.3,0.3v1.5c0,0.2-0.2,0.3-0.3,0.3h-0.9c-1,0-1.1,0.5-1.1,1.2v1.5h2.1C16.1,11.3,16.2,11.5,16.2,11.7L16.2,11.7L16.2,11.7z"/></svg>');}
    [social="linkedin"]  { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%238ca2d0;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M9.7,19c0,0.2-0.2,0.4-0.4,0.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-7.8c0-0.2,0.2-0.4,0.4-0.4h1.9c0.2,0,0.4,0.2,0.4,0.4V19H9.7z M10,8.2c0,1-0.8,1.8-1.8,1.8S6.5,9.2,6.5,8.2s0.8-1.8,1.8-1.8C9.3,6.5,10,7.3,10,8.2L10,8.2z M19,19.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-3.7c0-0.5,0.2-2.4-1.4-2.4c-1.2,0-1.5,1.3-1.5,1.8V19c0,0.2-0.2,0.4-0.4,0.4h-1.9c-0.2,0-0.4-0.2-0.4-0.4v-7.9c0-0.2,0.2-0.4,0.4-0.4h1.9c0.2,0,0.4,0.2,0.4,0.4v0.7c0.5-0.7,1.1-1.2,2.6-1.2c3.2,0,3.2,3,3.2,4.6V19l0,0C19.4,19.2,19.2,19.4,19,19.4z"/></svg>'); }
    [social="youtube"]   { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%238ca2d0;}</style><g><polygon class="st0" points="11.4,15.4 15.4,12.9 11.4,10.5 "/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M19.1,16.1c-0.3,0.8-1.1,1.3-1.9,1.4c-2.8,0.3-5.7,0.3-8.5,0c-0.8-0.1-1.6-0.6-1.9-1.4c-0.4-2.1-0.4-4.2,0-6.2C7,9,7.9,8.6,8.7,8.4c2.8-0.3,5.7-0.3,8.5,0C18,8.6,18.9,9,19.1,9.8C19.5,11.9,19.5,14,19.1,16.1z"/></g></svg>');}
    [social="instagram"] { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%238ca2d0;}</style><g><path class="st0" d="M15.6,7.8h-5.2c-1.4,0-2.6,1.1-2.6,2.6v5.2c0,1.4,1.1,2.6,2.6,2.6h5.2c1.4,0,2.6-1.1,2.6-2.6v-5.2C18.1,8.9,17,7.8,15.6,7.8z M12.9,16.3c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S14.8,16.3,12.9,16.3z M16.3,10.4c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8C17.1,10.1,16.8,10.4,16.3,10.4z"/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M19.4,15.6c0,2.1-1.7,3.9-3.9,3.9h-5.2c-2.1,0-3.9-1.7-3.9-3.9v-5.2c0-2.1,1.7-3.9,3.9-3.9h5.2c2.1,0,3.9,1.7,3.9,3.9V15.6z"/><path class="st0" d="M12.9,10.9c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2C15,11.8,14.1,10.9,12.9,10.9z"/></g></svg>');}
    [social="x"]         { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%238ca2d0;}</style><g><polygon class="st0" points="13.1,11.8 9.6,6.9 7.8,6.9 12.1,13 12.6,13.8 12.6,13.8 16.3,19 18.1,19 13.6,12.6 "/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M15.7,19.9L12,14.5l-4.6,5.4H6.2l5.3-6.2L6.2,6h4l3.5,5.1L18.1,6h1.2l-5.1,5.9l0,0l5.5,8H15.7z"/></g></svg>');}
    [social="link"]      { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%238ca2d0;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M17.6,16.6c-0.3,0.3-0.7,0.7-1,1c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.2-0.4-0.3l-3.1-3.1l0,0v0.1c-0.3,0.8-0.6,1.5-1,2.3c-0.1,0.2-0.3,0.4-0.5,0.3c-0.2,0-0.4-0.2-0.4-0.4c-0.4-1.3-0.8-2.6-1.2-3.9c-0.6-1.3-1-2.5-1.4-3.8C8.1,8.6,8.1,8.4,8.3,8.3c0.1-0.1,0.2-0.1,0.3-0.1h0.2c0.8,0.3,1.5,0.5,2.2,0.7c1.8,0.6,3.7,1.2,5.5,1.7c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.3,0.2,0.3,0.4s-0.1,0.4-0.3,0.5c-0.4,0.2-0.8,0.4-1.2,0.5c-0.4,0.2-0.7,0.3-1.1,0.5c0,0,0,0-0.1,0c0,0,0,0,0.1,0.1c1,1,2.1,2.1,3.1,3.1l0.1,0.1C17.8,16.1,17.8,16.4,17.6,16.6L17.6,16.6z"/></svg>'); }
}
body[site="connect"] {
    [social] {  background: no-repeat center center/contain; }
    [social="facebook"]  { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23e58930;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M16.2,11.7L16,13.5c0,0.2-0.2,0.3-0.3,0.3h-1.9v5.4c0,0.1-0.1,0.2-0.2,0.2h-1.9c-0.1,0-0.2-0.1-0.2-0.2v-5.4H10c-0.2,0-0.3-0.2-0.3-0.3v-1.8c0-0.2,0.2-0.3,0.3-0.3h1.4V9.6c0-2,1.2-3.1,3-3.1h1.4c0.2,0,0.3,0.2,0.3,0.3v1.5c0,0.2-0.2,0.3-0.3,0.3h-0.9c-1,0-1.1,0.5-1.1,1.2v1.5h2.1C16.1,11.3,16.2,11.5,16.2,11.7L16.2,11.7L16.2,11.7z"/></svg>');}
    [social="linkedin"]  { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23e58930;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M9.7,19c0,0.2-0.2,0.4-0.4,0.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-7.8c0-0.2,0.2-0.4,0.4-0.4h1.9c0.2,0,0.4,0.2,0.4,0.4V19H9.7z M10,8.2c0,1-0.8,1.8-1.8,1.8S6.5,9.2,6.5,8.2s0.8-1.8,1.8-1.8C9.3,6.5,10,7.3,10,8.2L10,8.2z M19,19.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-3.7c0-0.5,0.2-2.4-1.4-2.4c-1.2,0-1.5,1.3-1.5,1.8V19c0,0.2-0.2,0.4-0.4,0.4h-1.9c-0.2,0-0.4-0.2-0.4-0.4v-7.9c0-0.2,0.2-0.4,0.4-0.4h1.9c0.2,0,0.4,0.2,0.4,0.4v0.7c0.5-0.7,1.1-1.2,2.6-1.2c3.2,0,3.2,3,3.2,4.6V19l0,0C19.4,19.2,19.2,19.4,19,19.4z"/></svg>'); }
    [social="youtube"]   { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23e58930;}</style><g><polygon class="st0" points="11.4,15.4 15.4,12.9 11.4,10.5 "/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M19.1,16.1c-0.3,0.8-1.1,1.3-1.9,1.4c-2.8,0.3-5.7,0.3-8.5,0c-0.8-0.1-1.6-0.6-1.9-1.4c-0.4-2.1-0.4-4.2,0-6.2C7,9,7.9,8.6,8.7,8.4c2.8-0.3,5.7-0.3,8.5,0C18,8.6,18.9,9,19.1,9.8C19.5,11.9,19.5,14,19.1,16.1z"/></g></svg>');}
    [social="instagram"] { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23e58930;}</style><g><path class="st0" d="M15.6,7.8h-5.2c-1.4,0-2.6,1.1-2.6,2.6v5.2c0,1.4,1.1,2.6,2.6,2.6h5.2c1.4,0,2.6-1.1,2.6-2.6v-5.2C18.1,8.9,17,7.8,15.6,7.8z M12.9,16.3c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S14.8,16.3,12.9,16.3z M16.3,10.4c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8C17.1,10.1,16.8,10.4,16.3,10.4z"/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M19.4,15.6c0,2.1-1.7,3.9-3.9,3.9h-5.2c-2.1,0-3.9-1.7-3.9-3.9v-5.2c0-2.1,1.7-3.9,3.9-3.9h5.2c2.1,0,3.9,1.7,3.9,3.9V15.6z"/><path class="st0" d="M12.9,10.9c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2C15,11.8,14.1,10.9,12.9,10.9z"/></g></svg>');}
    [social="x"]         { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23e58930;}</style><g><polygon class="st0" points="13.1,11.8 9.6,6.9 7.8,6.9 12.1,13 12.6,13.8 12.6,13.8 16.3,19 18.1,19 13.6,12.6 "/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M15.7,19.9L12,14.5l-4.6,5.4H6.2l5.3-6.2L6.2,6h4l3.5,5.1L18.1,6h1.2l-5.1,5.9l0,0l5.5,8H15.7z"/></g></svg>');}
    [social="link"]      { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23e58930;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M17.6,16.6c-0.3,0.3-0.7,0.7-1,1c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.2-0.4-0.3l-3.1-3.1l0,0v0.1c-0.3,0.8-0.6,1.5-1,2.3c-0.1,0.2-0.3,0.4-0.5,0.3c-0.2,0-0.4-0.2-0.4-0.4c-0.4-1.3-0.8-2.6-1.2-3.9c-0.6-1.3-1-2.5-1.4-3.8C8.1,8.6,8.1,8.4,8.3,8.3c0.1-0.1,0.2-0.1,0.3-0.1h0.2c0.8,0.3,1.5,0.5,2.2,0.7c1.8,0.6,3.7,1.2,5.5,1.7c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.3,0.2,0.3,0.4s-0.1,0.4-0.3,0.5c-0.4,0.2-0.8,0.4-1.2,0.5c-0.4,0.2-0.7,0.3-1.1,0.5c0,0,0,0-0.1,0c0,0,0,0,0.1,0.1c1,1,2.1,2.1,3.1,3.1l0.1,0.1C17.8,16.1,17.8,16.4,17.6,16.6L17.6,16.6z"/></svg>'); }

}
body[site="thrive"] { 
    [social] {  background: no-repeat center center/contain; }
    [social="facebook"]  { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23d36a54;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M16.2,11.7L16,13.5c0,0.2-0.2,0.3-0.3,0.3h-1.9v5.4c0,0.1-0.1,0.2-0.2,0.2h-1.9c-0.1,0-0.2-0.1-0.2-0.2v-5.4H10c-0.2,0-0.3-0.2-0.3-0.3v-1.8c0-0.2,0.2-0.3,0.3-0.3h1.4V9.6c0-2,1.2-3.1,3-3.1h1.4c0.2,0,0.3,0.2,0.3,0.3v1.5c0,0.2-0.2,0.3-0.3,0.3h-0.9c-1,0-1.1,0.5-1.1,1.2v1.5h2.1C16.1,11.3,16.2,11.5,16.2,11.7L16.2,11.7L16.2,11.7z"/></svg>');}
    [social="linkedin"]  { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23d36a54;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M9.7,19c0,0.2-0.2,0.4-0.4,0.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-7.8c0-0.2,0.2-0.4,0.4-0.4h1.9c0.2,0,0.4,0.2,0.4,0.4V19H9.7z M10,8.2c0,1-0.8,1.8-1.8,1.8S6.5,9.2,6.5,8.2s0.8-1.8,1.8-1.8C9.3,6.5,10,7.3,10,8.2L10,8.2z M19,19.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-3.7c0-0.5,0.2-2.4-1.4-2.4c-1.2,0-1.5,1.3-1.5,1.8V19c0,0.2-0.2,0.4-0.4,0.4h-1.9c-0.2,0-0.4-0.2-0.4-0.4v-7.9c0-0.2,0.2-0.4,0.4-0.4h1.9c0.2,0,0.4,0.2,0.4,0.4v0.7c0.5-0.7,1.1-1.2,2.6-1.2c3.2,0,3.2,3,3.2,4.6V19l0,0C19.4,19.2,19.2,19.4,19,19.4z"/></svg>'); }
    [social="youtube"]   { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23d36a54;}</style><g><polygon class="st0" points="11.4,15.4 15.4,12.9 11.4,10.5 "/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M19.1,16.1c-0.3,0.8-1.1,1.3-1.9,1.4c-2.8,0.3-5.7,0.3-8.5,0c-0.8-0.1-1.6-0.6-1.9-1.4c-0.4-2.1-0.4-4.2,0-6.2C7,9,7.9,8.6,8.7,8.4c2.8-0.3,5.7-0.3,8.5,0C18,8.6,18.9,9,19.1,9.8C19.5,11.9,19.5,14,19.1,16.1z"/></g></svg>');}
    [social="instagram"] { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23d36a54;}</style><g><path class="st0" d="M15.6,7.8h-5.2c-1.4,0-2.6,1.1-2.6,2.6v5.2c0,1.4,1.1,2.6,2.6,2.6h5.2c1.4,0,2.6-1.1,2.6-2.6v-5.2C18.1,8.9,17,7.8,15.6,7.8z M12.9,16.3c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S14.8,16.3,12.9,16.3z M16.3,10.4c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8C17.1,10.1,16.8,10.4,16.3,10.4z"/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M19.4,15.6c0,2.1-1.7,3.9-3.9,3.9h-5.2c-2.1,0-3.9-1.7-3.9-3.9v-5.2c0-2.1,1.7-3.9,3.9-3.9h5.2c2.1,0,3.9,1.7,3.9,3.9V15.6z"/><path class="st0" d="M12.9,10.9c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2C15,11.8,14.1,10.9,12.9,10.9z"/></g></svg>');}
    [social="x"]         { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23d36a54;}</style><g><polygon class="st0" points="13.1,11.8 9.6,6.9 7.8,6.9 12.1,13 12.6,13.8 12.6,13.8 16.3,19 18.1,19 13.6,12.6 "/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M15.7,19.9L12,14.5l-4.6,5.4H6.2l5.3-6.2L6.2,6h4l3.5,5.1L18.1,6h1.2l-5.1,5.9l0,0l5.5,8H15.7z"/></g></svg>');}
    [social="link"]      { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23d36a54;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M17.6,16.6c-0.3,0.3-0.7,0.7-1,1c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.2-0.4-0.3l-3.1-3.1l0,0v0.1c-0.3,0.8-0.6,1.5-1,2.3c-0.1,0.2-0.3,0.4-0.5,0.3c-0.2,0-0.4-0.2-0.4-0.4c-0.4-1.3-0.8-2.6-1.2-3.9c-0.6-1.3-1-2.5-1.4-3.8C8.1,8.6,8.1,8.4,8.3,8.3c0.1-0.1,0.2-0.1,0.3-0.1h0.2c0.8,0.3,1.5,0.5,2.2,0.7c1.8,0.6,3.7,1.2,5.5,1.7c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.3,0.2,0.3,0.4s-0.1,0.4-0.3,0.5c-0.4,0.2-0.8,0.4-1.2,0.5c-0.4,0.2-0.7,0.3-1.1,0.5c0,0,0,0-0.1,0c0,0,0,0,0.1,0.1c1,1,2.1,2.1,3.1,3.1l0.1,0.1C17.8,16.1,17.8,16.4,17.6,16.6L17.6,16.6z"/></svg>'); }
}

body[site="global"],
body[site="accelerate"],
body[site="connect"],
body[site="thrive"] { 
  [social]:hover { background: no-repeat center center/contain; }
  [social=facebook]:hover { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23ffffff;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M16.2,11.7L16,13.5c0,0.2-0.2,0.3-0.3,0.3h-1.9v5.4c0,0.1-0.1,0.2-0.2,0.2h-1.9c-0.1,0-0.2-0.1-0.2-0.2v-5.4H10c-0.2,0-0.3-0.2-0.3-0.3v-1.8c0-0.2,0.2-0.3,0.3-0.3h1.4V9.6c0-2,1.2-3.1,3-3.1h1.4c0.2,0,0.3,0.2,0.3,0.3v1.5c0,0.2-0.2,0.3-0.3,0.3h-0.9c-1,0-1.1,0.5-1.1,1.2v1.5h2.1C16.1,11.3,16.2,11.5,16.2,11.7L16.2,11.7L16.2,11.7z"/></svg>');}
  [social=linkedin]:hover {background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23ffffff;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M9.7,19c0,0.2-0.2,0.4-0.4,0.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-7.8c0-0.2,0.2-0.4,0.4-0.4h1.9c0.2,0,0.4,0.2,0.4,0.4V19H9.7z M10,8.2c0,1-0.8,1.8-1.8,1.8S6.5,9.2,6.5,8.2s0.8-1.8,1.8-1.8C9.3,6.5,10,7.3,10,8.2L10,8.2z M19,19.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-3.7c0-0.5,0.2-2.4-1.4-2.4c-1.2,0-1.5,1.3-1.5,1.8V19c0,0.2-0.2,0.4-0.4,0.4h-1.9c-0.2,0-0.4-0.2-0.4-0.4v-7.9c0-0.2,0.2-0.4,0.4-0.4h1.9c0.2,0,0.4,0.2,0.4,0.4v0.7c0.5-0.7,1.1-1.2,2.6-1.2c3.2,0,3.2,3,3.2,4.6V19l0,0C19.4,19.2,19.2,19.4,19,19.4z"/></svg>'); }
  [social=youtube]:hover { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23ffffff;}</style><g><polygon class="st0" points="11.4,15.4 15.4,12.9 11.4,10.5 "/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M19.1,16.1c-0.3,0.8-1.1,1.3-1.9,1.4c-2.8,0.3-5.7,0.3-8.5,0c-0.8-0.1-1.6-0.6-1.9-1.4c-0.4-2.1-0.4-4.2,0-6.2C7,9,7.9,8.6,8.7,8.4c2.8-0.3,5.7-0.3,8.5,0C18,8.6,18.9,9,19.1,9.8C19.5,11.9,19.5,14,19.1,16.1z"/></g></svg>'); }
  [social=instagram]:hover { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23ffffff;}</style><g><path class="st0" d="M15.6,7.8h-5.2c-1.4,0-2.6,1.1-2.6,2.6v5.2c0,1.4,1.1,2.6,2.6,2.6h5.2c1.4,0,2.6-1.1,2.6-2.6v-5.2C18.1,8.9,17,7.8,15.6,7.8z M12.9,16.3c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S14.8,16.3,12.9,16.3z M16.3,10.4c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8C17.1,10.1,16.8,10.4,16.3,10.4z"/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M19.4,15.6c0,2.1-1.7,3.9-3.9,3.9h-5.2c-2.1,0-3.9-1.7-3.9-3.9v-5.2c0-2.1,1.7-3.9,3.9-3.9h5.2c2.1,0,3.9,1.7,3.9,3.9V15.6z"/><path class="st0" d="M12.9,10.9c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2C15,11.8,14.1,10.9,12.9,10.9z"/></g></svg>');}
  [social=x]:hover { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23ffffff;}</style><g><polygon class="st0" points="13.1,11.8 9.6,6.9 7.8,6.9 12.1,13 12.6,13.8 12.6,13.8 16.3,19 18.1,19 13.6,12.6 "/><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M15.7,19.9L12,14.5l-4.6,5.4H6.2l5.3-6.2L6.2,6h4l3.5,5.1L18.1,6h1.2l-5.1,5.9l0,0l5.5,8H15.7z"/></g></svg>'); }
  [social=link]:hover { background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25.9px" height="25.9px" viewBox="0 0 25.9 25.9" style="enable-background:new 0 0 25.9 25.9;" xml:space="preserve"><style type="text/css">.st0{fill:%23ffffff;}</style><path class="st0" d="M12.9,0C5.8,0,0,5.8,0,12.9s5.8,12.9,12.9,12.9S25.8,20,25.8,12.9C25.9,5.8,20.1,0,12.9,0z M17.6,16.6c-0.3,0.3-0.7,0.7-1,1c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.2-0.4-0.3l-3.1-3.1l0,0v0.1c-0.3,0.8-0.6,1.5-1,2.3c-0.1,0.2-0.3,0.4-0.5,0.3c-0.2,0-0.4-0.2-0.4-0.4c-0.4-1.3-0.8-2.6-1.2-3.9c-0.6-1.3-1-2.5-1.4-3.8C8.1,8.6,8.1,8.4,8.3,8.3c0.1-0.1,0.2-0.1,0.3-0.1h0.2c0.8,0.3,1.5,0.5,2.2,0.7c1.8,0.6,3.7,1.2,5.5,1.7c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.3,0.2,0.3,0.4s-0.1,0.4-0.3,0.5c-0.4,0.2-0.8,0.4-1.2,0.5c-0.4,0.2-0.7,0.3-1.1,0.5c0,0,0,0-0.1,0c0,0,0,0,0.1,0.1c1,1,2.1,2.1,3.1,3.1l0.1,0.1C17.8,16.1,17.8,16.4,17.6,16.6L17.6,16.6z"/></svg>'); } 
}