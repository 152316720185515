/// Buttons
.buttons { //buttongroup
  .button { margin-right: $baseline/2; }
}

button:not(.arrow),
.button {
  display: inline-block;
  font-size: $ono*14;
  font-weight: bold;
  border-radius: $baseline; 
  border: $ono*2 solid $highlight3; 
  line-height: $ono*18;
  padding: $space/2 $space; 
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @include transition;
  
  &:not(.outline):not(.full):not(.play):not(.arrow),
  &.outline { 
    background: transparent;
    color: $highlight3;
  }
  
  &.full {
    background: $highlight1; 
    color: $white;
    border-color: $highlight1; 
  }


  &:not(.outline):not(.full):not(.play):hover,
  &.full:hover,
  &.outline:hover {
    border-color: $highlight3;
    background: $highlight3; 
    color: $white;
    padding: $space/2 $space*1.25; 
  }
  
  &.full.hover:hover,
  &.outline.hover:hover { 
    border-color: $white;
    background: $white; 
    color: $highlight3;
    padding: $space/2 $space*1.25; 
  }
}

section li:has(.button):has(a.clicker:hover) .button {
    border-color: $highlight3;
    background: $highlight3; 
    color: $white;
    padding: $space/2 $space*1.25; 
}


.arrow {
  width: 40*$ono; height: 40*$ono;
  background: no-repeat center center/contain;
  text-indent: -100vh; overflow: hidden;
  border: none;
  @include transition;
  
  &:hover { opacity: .75; cursor: pointer; }
  
  &.left { 
    &:hover { margin: 0 ($baseline/2) 0 (-($baseline/2)); }
    background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve"><style type="text/css">.st0{clip-path:url(%23SVGID_00000140698760293527237460000008040903118660060551_);}.st1{fill:none;stroke:%23000000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}.st2{fill:none;stroke:%23000000;stroke-width:1.3;stroke-linecap:round;stroke-linejoin:round;}</style><g><defs><rect id="SVGID_1_" y="0" width="40" height="40"/></defs><clipPath id="SVGID_00000053528047849231005190000004208701345049994153_"><use xlink:href="%23SVGID_1_" style="overflow:visible;"/></clipPath><g style="clip-path:url(%23SVGID_00000053528047849231005190000004208701345049994153_);"><path class="st1" d="M37.6,12.5c-1.7-4.1-4.8-7.4-8.7-9.5C25,1,20.5,0.3,16.2,1.2C11.8,2.1,8,4.5,5.2,7.9C2.4,11.3,0.8,15.6,0.8,20c0,4.4,1.5,8.7,4.3,12.1s6.7,5.8,11,6.7C20.5,39.7,25,39,28.9,37c3.9-2.1,7-5.4,8.7-9.5"/><path class="st2" d="M18.2,13.3L11.5,20l6.7,6.7"/><path class="st1" d="M12.5,20h26.7"/></g></g></svg>');
  }
  
  &.right {
    &:hover { margin: 0 (-($baseline/2)) 0 ($baseline/2); } 
    background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve"><style type="text/css">.st0{clip-path:url(%23SVGID_00000118388985691532727670000016835960325989288383_);}.st1{fill:none;stroke:%23000000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}.st2{fill:none;stroke:%23000000;stroke-width:1.3;stroke-linecap:round;stroke-linejoin:round;}</style><g><defs><rect id="SVGID_1_" y="0" width="40" height="40"/></defs><clipPath id="SVGID_00000085231335351480006480000001795020355813419153_"><use xlink:href="%23SVGID_1_" style="overflow:visible;"/></clipPath><g style="clip-path:url(%23SVGID_00000085231335351480006480000001795020355813419153_);"><path class="st1" d="M2.4,27.5c1.7,4.1,4.8,7.4,8.7,9.5c3.9,2.1,8.4,2.7,12.7,1.8c4.3-0.9,8.2-3.2,11-6.7c2.8-3.4,4.3-7.7,4.3-12.1c0-4.4-1.5-8.7-4.3-12.1c-2.8-3.4-6.7-5.8-11-6.7C19.5,0.3,15,1,11.1,3c-3.9,2.1-7,5.4-8.7,9.5"/><path class="st2" d="M21.8,26.7l6.7-6.7l-6.7-6.7"/><path class="st1" d="M27.5,20H0.8"/></g></g></svg>');
  }
  
}



.nobg {
  .arrow { 
    &.left { 
      background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve"><style type="text/css">.st0{clip-path:url(%23SVGID_00000140698760293527237460000008040903118660060551_);}.st1{fill:none;stroke:%23ffffff;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}.st2{fill:none;stroke:%23ffffff;stroke-width:1.3;stroke-linecap:round;stroke-linejoin:round;}</style><g><defs><rect id="SVGID_1_" y="0" width="40" height="40"/></defs><clipPath id="SVGID_00000053528047849231005190000004208701345049994153_"><use xlink:href="%23SVGID_1_" style="overflow:visible;"/></clipPath><g style="clip-path:url(%23SVGID_00000053528047849231005190000004208701345049994153_);"><path class="st1" d="M37.6,12.5c-1.7-4.1-4.8-7.4-8.7-9.5C25,1,20.5,0.3,16.2,1.2C11.8,2.1,8,4.5,5.2,7.9C2.4,11.3,0.8,15.6,0.8,20c0,4.4,1.5,8.7,4.3,12.1s6.7,5.8,11,6.7C20.5,39.7,25,39,28.9,37c3.9-2.1,7-5.4,8.7-9.5"/><path class="st2" d="M18.2,13.3L11.5,20l6.7,6.7"/><path class="st1" d="M12.5,20h26.7"/></g></g></svg>'); 
    }
    &.right { 
      background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve"><style type="text/css">.st0{clip-path:url(%23SVGID_00000118388985691532727670000016835960325989288383_);}.st1{fill:none;stroke:%23ffffff;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}.st2{fill:none;stroke:%23ffffff;stroke-width:1.3;stroke-linecap:round;stroke-linejoin:round;}</style><g><defs><rect id="SVGID_1_" y="0" width="40" height="40"/></defs><clipPath id="SVGID_00000085231335351480006480000001795020355813419153_"><use xlink:href="%23SVGID_1_" style="overflow:visible;"/></clipPath><g style="clip-path:url(%23SVGID_00000085231335351480006480000001795020355813419153_);"><path class="st1" d="M2.4,27.5c1.7,4.1,4.8,7.4,8.7,9.5c3.9,2.1,8.4,2.7,12.7,1.8c4.3-0.9,8.2-3.2,11-6.7c2.8-3.4,4.3-7.7,4.3-12.1c0-4.4-1.5-8.7-4.3-12.1c-2.8-3.4-6.7-5.8-11-6.7C19.5,0.3,15,1,11.1,3c-3.9,2.1-7,5.4-8.7,9.5"/><path class="st2" d="M21.8,26.7l6.7-6.7l-6.7-6.7"/><path class="st1" d="M27.5,20H0.8"/></g></g></svg>'); 
    }
  } 

  button:not(.arrow),
  .button {
    display: inline-block;
    font-size: $ono*14;
    font-weight: bold;
    border-radius: $baseline; 
    border: $ono solid $white; 
    line-height: $ono*18;
    padding: $space/2 $space; 
    @include transition;
    
    &:not(.outline):not(.full):not(.play):not(.arrow),
    &.outline { 
      background: transparent;
      color: $white;
    }
    
    &.full {
      background: $white; 
      color: $highlight1;
      border-color: $white; 
    }
  
  
    &:not(.outline):not(.full):not(.play):hover,
    &.full:hover,
    &.outline:hover {
      border-color: $white;
      background: $white; 
      color: $highlight3;
      padding: $space/2 $space*1.25; 
    }
  }
}






button.darken,
.button.darken {
  display: inline-block;
  font-size: $ono*14;
  font-weight: bold;
  border-radius: $baseline; 
  border: $ono*2 solid $highlight3; 
  line-height: $ono*18;
  padding: $space/2 $space; 
  @include transition;
  
  &:not(.outline):not(.full):not(.play),
  &.outline { 
    background: transparent;
    color: $highlight3;
  }
  
  &.full {
    background: $highlight3; 
    color: $white;
    border-color: $highlight3; 
  }


  &:not(.outline):not(.full):not(.play):hover,
  &.full:hover,
  &.outline:hover {
    border-color: $highlight1;
    background: $highlight1; 
    color: $white;
    padding: $space/2 $space*1.25; 
  }
  
  &.full.hover:hover,
  &.outline.hover:hover { 
    border-color: $white;
    background: $white; 
    color: $highlight1;
    padding: $space/2 $space*1.25; 
  }
}
