section.programme {
  .inner { 
    max-width: 100%; 
    width: $widths; 
    
    @include mobile { 
      padding: $space/2;
    }
  }
  
  ul {
    li {
      list-style: none;
      &.closed { pointer-events: none; }
      @include desktop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &:not(:last-child) { 
          margin-bottom: $space * 1.5;
        }
      }
      
      @include mobile {
        &:not(:last-child) { 
          margin-bottom: $space/1.5;
        }
      }
      
    }
    
    li .inner {    
      &, .marquee { @include transition; }
      &.closed { pointer-events: none; }
      
      position: relative;
      
      display: flex;
      
      border-radius: $radius;
      position: relative;
      background: $highlight2; 
            ;
      
      @include desktop {
        flex-direction: row;
        justify-content: space-between;
        padding: $space*2 $space;
        
      }
      
      @include mobile { 
        padding: $space*1.25 $space/2;
        flex-direction: column;
        align-items: flex-start;
        .button { margin-top: $baseline; }
        
      }
      
      
      h3 { 
        color: $highlight3;
        @include desktop { 
          font-size: 42*$ono;
        }
        @include mobile { 
          font-size: 28*$ono;
        }
      }
      
      .marquee { 
        position: absolute;
        bottom: 0; left: 0; right: 0; 
        background: $highlight1; 
        max-height: 0;
        overflow: hidden; 
        border-bottom-right-radius: $radius;
        border-bottom-left-radius: $radius;
        overflow: hidden;
        opacity:0; 
        text-transform: uppercase;
        font-weight: bold; 

        & > div { 
          width: max-content;
          & > div { 
            display: inline-block;
            animation: Scroll 240s forwards linear infinite;
            p { white-space: nowrap; display: inline-block; }
          }
        }
      }
      
      &:hover { background: $white; 
        @include desktop { 
          padding: $space*1.5 $space $space*2.5; 
        }
        
        @include mobile {
          padding: $space*1.5 $space/2 $space*2; 
        }
        .marquee { 
          padding: $baseline/4; 
          opacity:1; 
          max-height: 100vh; 
          color: $highlight3;
          font-weight: bold;
        }
      }
    }
  }
}

@keyframes Scroll {
  0%{transform: translate(0, 0)}
  100%{transform: translate(-100%, 0)}
}