.checkbox-label {
    display: flex;
    align-items: center;
    gap: $baseline/2;

    .field-checkbox-wrapper {
        position: relative;
        border: none;
        border-radius: 100%;
        margin: 0;
        display: inline-block;
        background-color: $white;

        .field-input-checkbox {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            z-index: 10;
        }

        .checkbox-filling {
            position: absolute;
            background: $highlight3;
            width: 60%;
            height: 60%;
            opacity: 0;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            z-index: 9;
        }

        .field-input-checkbox:checked~.checkbox-filling {
            opacity: 1;
        }
    }

}