.widget.schedulelist{ 
    .inner{
        background-color: $highlight2;
        border-radius: $radius;
        
        @include desktop {
          padding: $space * 1.5;
          width: $widthm; 
          max-width: 100%;
        }
        
        @include mobile {
          margin: $space/4; 
        }
    }
    .schedule-head{
        display: flex;
        align-items: center;
        margin-right: $space;
        color: $background;
        
        @include desktop {
          h4, .date { width: 13%; }
          .button{
            margin-left: auto;
          }
        }

        &.btn{
            align-items: center;
        }

        h4{
            @include desktop {margin-right: $space; }
            font-size: 24*$ono;
        }
        .price{
            padding: $space/4 $space/2;
            @include desktop {
              margin-right: $space;
              margin-left: auto;
            }
            color: $white;
            background-color: $highlight1;
            border-radius: $radius;
            border: 2*$ono solid $highlight1;
            font-weight: bold;
        }
        .date {
          @include desktop { margin-right: $space; }
            min-width: $ono*200;
            span{
                @include mobile { display: inline-block; }
                padding: $space/4 $space/2;
                border-radius: $radius;
                border: 2*$ono solid $background;
            }
        }
        .title{
            font-size: 24*$ono;
            font-family: 'base', sans-serif;
            font-weight: 500;
        }
    }
    .schedule-body{
        li{
            @include desktop {
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
            padding: $space 0;
            &:not(:first-child){
                border-top: solid $ono $highlight1;
            }
        }
    }
    .accordion-toggle{
        top: $ono*4;
    }
    .accordion-item-open{
        .price{
            background-color: transparent;
            color: $background;
            border: 2*$ono solid $background;
        }
    }
    
    
    .accordion-item {
        @include mobile {
          .schedule-head {
              align-items: flex-start;
              flex-direction: column;
          }
          .schedule-head > * {
            @include transition; 
          }

          &.accordion-item-open .schedule-head {
            & > *:not(.title) { overflow: hidden; }
            & > * { margin: $baseline/2 0; }
          }

          &:not(.accordion-item-open) {
            .schedule-head {
              & > *:not(.title) { padding: 0; opacity: 0; overflow: hidden; max-height: 0; }
            }
          }
        }
    }
}