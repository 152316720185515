section.imagebox {
  .inner { 
    @include desktop { width: $widthm; }
    
    h3 { 
      text-align: center; 
        color: $white;
    }
    
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      
      @include desktop { margin: 0 -$space; }

      &, li { list-style: none; }
      li { 
        @include desktop { padding: $space $space 0; }
        @include mobile { padding: $space/2 $space/2 0; }
        
      }
      
      li.title { 
        color: $highlight1; 
        
        @include desktop {
          width: $widthm; 
          //font-size: 32*$ono;
        }
        @include mobile{
          width: 100%;
          //font-size: 1.764rem;
        }
      }
      
      li.img { 
        img { max-width: 100%; }
        display: flex;
        flex-direction: column;
        justify-content: center;
          
        @include desktop { 
         a { padding: $space; }
          width: $widthm/4; 
          
        }
        
        @include mobile {
          a { padding: $space $space*1.5; }
          padding: 0; 
          width: 50%; 
        }
        img { max-width: 100%; }
      }
    }
  }
}