.widget.slides{
    .inner{
        //width: $widthl;
    }
    .swiper-slide {
        max-width: fit-content;
    }
    .item {
        display: flex;
        align-items: center;
        margin: 0 0 ($baseline*2);
        
        @include desktop {
          padding: $space*2 0 $space*2 $space*2;
          width: 57rem; height: (57rem / 1.76447876);
        }
        
        @include mobile {
          padding: $space/2;
          width: 90vw;
        }
        
        border-radius: $radius;
        overflow: hidden;
        position: relative;

        .text { z-index: 3;
            @include desktop { max-width: 30%;  
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;
                align-items: flex-start;
            }
            @include mobile { margin-top: 80vw; }
            
            h3 {
                color: $background;
                font-size: 42*$ono;
                margin-bottom: $space/2;
            }
            
            p {
                font-size: 16*$ono; 
                color: $background;
                margin-bottom: $space/2;
                strong { color: $background; }
            }
            
            
            .bottom { 
            //  @include desktop { margin-top: $space*5; }
              @include mobile { margin: $space 0; }
            }
        }
        
        .buttongroup {
          .button.outline { background: $highlight2; border-color: $background; color: $background; }
          .button { margin-bottom: $baseline/4; }
        }
        
        img,.pattern {
          position: absolute;
          top: 0; right: 0; bottom: 0; left: 0; 
          width: 100%; 
          
          @include desktop { bottom: 0; height: 100%; }
          @include mobile { height: 80vw ; }
        }
        
        .pattern {
          @include mobile { height: 80.5vw ; }
        }
        
        @include mobile { background: $highlight2; }
        
        img { object-fit: cover; z-index: 1; }
        .pattern { background: $patternultra; z-index: 2; 
          @include mobile {
            background-position: center right;
            background-size: cover;
          }
        }
    }
}