body[site="global"],.site-global {
    --background: #444f22;
    --backgroundO: rgba(68, 79, 34, 0.5);
    --backgroundO0: rgba(68, 79, 34, 0);
    --color: var(--background);
    --highlight1: #b8b43c;
    --highlight2: #ebe6bf;
    --highlight3: var(--background);
    --pattern: url('//global.scotch.wa.edu.au/assets/patterns/global.svg?v=1.3') #444f22 center center/12rem;//13.125rem; 
    --patternmobile: url('//global.scotch.wa.edu.au/assets/patterns/global.svg?v=1.3') #444f22 center center/7.5rem;//6.5625rem; 
    --patternmed: url('//global.scotch.wa.edu.au/assets/patterns/global-medium.svg') #b8b43c center center/cover; 
    --patternbig: url('//global.scotch.wa.edu.au/assets/patterns/global-big.svg') #b8b43c center center/cover; 
    --patternultra: url('//global.scotch.wa.edu.au/assets/patterns/global-ultra.svg') center center/cover; 
    --arrow: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="8.8px" height="5.1px" viewBox="0 0 8.8 5.1" style="enable-background:new 0 0 8.8 5.1;" xml:space="preserve"><style type="text/css">.st0{fill:%23ebe6bf;}</style><path class="st0" d="M0.7,0C0.1,0-0.2,0.7,0.2,1.1l3.7,3.7c0.3,0.3,0.7,0.3,0.9,0l3.7-3.7C9,0.7,8.7,0,8.1,0H0.7z"/></svg>');

    
    --black: #1E1E1E;
    --white: #F5F5F5; 
}
body[site="accelerate"],.site-accelerate {
    --background: #1a464e;
    --backgroundO: rgba(26, 70, 78, 0.5);
    --backgroundO0: rgba(26, 70, 78, 0);
    --color: var(--background);
    --highlight1: #8ca2d0;
    --highlight2: #c6d7d9;
    --highlight3: var(--background);
    --pattern: url('//global.scotch.wa.edu.au/assets/patterns/accelerate.svg?v=1') #1a464e center center/7rem;//7.813rem; 
    --patternmobile: url('//global.scotch.wa.edu.au/assets/patterns/accelerate.svg?v=1') #1a464e center center/4rem;//3.9065rem; 
    --patternmed: url('//global.scotch.wa.edu.au/assets/patterns/accelerate-medium.svg') #b8b43c center center/cover; 
    --patternbig: url('//global.scotch.wa.edu.au/assets/patterns/accelerate-big.svg') #b8b43c center center/cover; 
    --patternultra: url('//global.scotch.wa.edu.au/assets/patterns/accelerate-ultra.svg') center center/cover; 
    --arrow: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="8.8px" height="5.1px" viewBox="0 0 8.8 5.1" style="enable-background:new 0 0 8.8 5.1;" xml:space="preserve"><style type="text/css">.st0{fill:%23c6d7d9;}</style><path class="st0" d="M0.7,0C0.1,0-0.2,0.7,0.2,1.1l3.7,3.7c0.3,0.3,0.7,0.3,0.9,0l3.7-3.7C9,0.7,8.7,0,8.1,0H0.7z"/></svg>');

    
    --black: #1E1E1E;
    --white: #F5F5F5; 
}
body[site="connect"],.site-connect {
    --background: #832818;
    --backgroundO: rgba(131, 40, 24, 0.5);
    --backgroundO0: rgba(131, 40, 24, 0);
    --color: var(--background);
    --highlight1: #e58930;
    --highlight2: #eac7a4;
    --highlight3: var(--background);
    --pattern: url('//global.scotch.wa.edu.au/assets/patterns/connect.svg?v=1') #832818 center center/6.5rem;//7.813rem; 
    --patternmobile: url('//global.scotch.wa.edu.au/assets/patterns/connect.svg?v=1') #832818 center center/4rem;//3.9065rem; 
    --patternmed: url('//global.scotch.wa.edu.au/assets/patterns/connect-medium.svg') #e58930 center center/cover; 
    --patternbig: url('//global.scotch.wa.edu.au/assets/patterns/connect-big.svg') #e58930 center center/cover; 
    --patternultra: url('//global.scotch.wa.edu.au/assets/patterns/connect-ultra.svg') center center/cover; 
    --arrow: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="8.8px" height="5.1px" viewBox="0 0 8.8 5.1" style="enable-background:new 0 0 8.8 5.1;" xml:space="preserve"><style type="text/css">.st0{fill:%23eac7a4;}</style><path class="st0" d="M0.7,0C0.1,0-0.2,0.7,0.2,1.1l3.7,3.7c0.3,0.3,0.7,0.3,0.9,0l3.7-3.7C9,0.7,8.7,0,8.1,0H0.7z"/></svg>');

    --black: #1E1E1E;
    --white: #F5F5F5;
}
body[site="thrive"],.site-thrive { 
    --background: #4d1825;
    --backgroundO: rgba(77, 24, 37, 0.5);
    --backgroundO0: rgba(77, 24, 37, 0);
    --color: var(--background);
    --highlight1: #d36a54;
    --highlight2: #dac2c1;
    --highlight3: var(--background);
    --pattern: url('//global.scotch.wa.edu.au/assets/patterns/thrive.svg?v=1.1') #4d1825 center center/6.5rem;//7.813rem; 
    --patternmobile: url('//global.scotch.wa.edu.au/assets/patterns/thrive.svg?v=1.1') #4d1825 center center/4rem;//3.9065rem; 
    --patternmed: url('//global.scotch.wa.edu.au/assets/patterns/thrive-medium.svg') #d36a54 center center/cover; 
    --patternbig: url('//global.scotch.wa.edu.au/assets/patterns/thrive-big.svg') #d36a54 center center/cover; 
    --patternultra: url('//global.scotch.wa.edu.au/assets/patterns/thrive-ultra.svg') center center/cover; 
    --arrow: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="8.8px" height="5.1px" viewBox="0 0 8.8 5.1" style="enable-background:new 0 0 8.8 5.1;" xml:space="preserve"><style type="text/css">.st0{fill:%23dac2c1;}</style><path class="st0" d="M0.7,0C0.1,0-0.2,0.7,0.2,1.1l3.7,3.7c0.3,0.3,0.7,0.3,0.9,0l3.7-3.7C9,0.7,8.7,0,8.1,0H0.7z"/></svg>');
    
    --black: #1E1E1E;
    --white: #F5F5F5; 
}


// Vars
$baseline: 1.5rem;
$font: "Helvetica neau", sans-serif;
$heading: "Helvetica neau", sans-serif;
$ono: 0.063rem;
$radius: 1.5rem;

$space: 2rem;
$widgetspace: $space;
$widthxl: calc(100vw - ($space*2));
$widthl: 86.250rem; // 1380px
$widthm: 76.750rem; // 1228px
$widths: 66.500rem; // 1064px 
$widthxs: 45rem; // 720px 
$maxwidth: $widthl; // 1228px
$fullwidth: $widthl; // 1228px

$pattern: var(--pattern);
$patternmobile: var(--patternmobile);
$patternmed: var(--patternmed);
$patternbig: var(--patternbig);
$patternultra: var(--patternultra);

$background: var(--background);
$backgroundO: var(--backgroundO);
$backgroundO0: var(--backgroundO0);

$highlight1: var(--highlight1);
$highlight2: var(--highlight2);
$highlight3: var(--highlight3);

$arrow: var(--arrow);

$black: var(--black);
$white: var(--white); 
$grey: #BBBBBB;
$lightgrey: #EDEEE8; 

// fix mobile bg 
html, body {
  background: $background;
}


// Media queries
html {
  @media screen and (min-width: 744px) and (max-width: 1179px) { font-size: 1vw; }
  @media screen and (min-width: 1180px) { font-size: 14px; }
  @media screen and (min-width: 1480px) { font-size: 16px; }
  @media screen and (min-width: 1780px) { font-size: 17px; }
  @media screen and (min-width: 2080px) { font-size: 18px; }
  @media screen and (min-width: 2280px) { font-size: 19px; }
  @media screen and (min-width: 2580px) { font-size: 20px; }
  @media screen and (min-width: 2880px) { font-size: 21px; }
}


$breakpoint-desktop: 743px;
@mixin mobile {
  @media (max-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop + 1px}) {
    @content;
  }
}

@include mobile { 
  body,#root,.webbox { max-width: 100vw; overflow-x: hidden; }
}



// Transition;
@mixin transition {
  transition: .125s all ease-in;
}

@include desktop {
  body:not(.touchdevice) .cursor {
    position: fixed; z-index: 10000;
    pointer-events: none; 
    &[style*=" 0px"]::after { opacity: 0; }
    
    &::after {
      @include transition;
      content: ''; display: block; 
      
      width: $space*2; height: $space*2;
      margin: (-$space) 0 0 (-$space);
      
      border-radius: 100%;
      
      background: rgba(255,255,255,.25);
      border: $ono solid $background;
      backdrop-filter: blur($baseline/2);
      -webkit-backdrop-filter: blur($baseline/2);
    }
    
    &.hover::after {
      width: $space; height: $space;
      margin: (-$space/2) 0 0 (-$space/2);
      border: $ono*2 solid $highlight1;
      backdrop-filter: blur($ono*2);
      -webkit-backdrop-filter: blur($ono*2);
    }
    &.mousedown::after {
      width: $space/2; height: $space/2;
      margin: (-$space/4) 0 0 (-$space/4);
      border: $ono*2 solid $highlight1;
      backdrop-filter: blur($ono*2);
      -webkit-backdrop-filter: blur($ono*2);
    }
  }
}



// Main
@import "main/base";
@import "main/fonts";
@import "main/Switch";
@import "main/Header";
@import "main/Footer";
@import "main/Animation";
@import "main/Widget";
@import "main/Socials";


// Widgets
@import "widgets/HeadSplit";
@import "widgets/Video";
@import "widgets/CTACards";
@import "widgets/Slides";
@import "widgets/Helper";
@import "widgets/Newsslider";
@import "widgets/Form";
@import "widgets/CTA";
@import "widgets/Quotes";
@import "widgets/Article";
@import "widgets/Newsletter";
@import "widgets/Accordion";
@import "widgets/SplitText";
@import "widgets/ImageSlider";
@import "widgets/DataList";
@import "widgets/ScheduleList";
@import "widgets/TeamList";
@import "widgets/FullPattern";
@import "widgets/Programme";
@import "widgets/LogoSlider.scss";
@import "widgets/DetailCard.scss";
@import "widgets/Itemlist.scss";
@import "widgets/Imagebox.scss";


// Components
@import "components/Buttons.scss";
@import "components/Input.scss";
@import "components/Checkbox.scss";
@import "components/TextArea.scss";
@import "components/FormComponent.scss";
@import "components/AccordionComponent.scss";
@import "components/Quote.scss";
@import "components/FilterButton.scss";
@import "components/FilterComponent.scss";
@import "components/Card.scss";
@import "components/Select.scss";
@import "components/FileInput.scss";