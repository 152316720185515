.widget.quotes{
    .inner{
        h6 { 
          font-weight: normal; 
          font-size: 16*$ono;
          margin-bottom: $baseline;
          
          @include desktop {
            font-size: 16*$ono;
          }
          @include mobile {
            font-size: 14*$ono;
          }
          
        }
        //width: $widthl;
        .item{
            @include transition;
            //height: $ono*280;
            padding: $space;
            border-radius: $radius;
            margin-bottom: $space;
            background: transparent;
            border: $ono*2 solid rgba(255,255,255,.25);
            color: rgba(255,255,255,.25);
            
            @include desktop {
              font-size: $ono*24;
            }
            @include mobile {
              font-size: $ono*19;
              width: 90vw;
            }
            
            a { color: $highlight3; text-decoration: underline; }
            
            &::before {
              display: block;
              content: ''; 
              width: $ono*32; height: $space*2;
              color: rgba(255,255,255,.25);
              font-size: $ono*80;
              line-height: 1em;
              margin-bottom: -.25em;
            }
        }
        
        .swiper-slide-active .item {
            background-color: $white;
            border-color: $white;
            color: $black;
            &::before {
              color: $highlight1;
            }
        }
        
        .controls {
            display: flex;
            justify-content: space-between;
            padding: 0 $space*2;
        }
    }
    
    @include mobile {
      .swiper-slide:not(.swiper-slide-active) { height: 0; overflow: hidden; }
    }
}

.swiper .controls {
    display: flex;
    justify-content: space-between;
    padding: 0 $space*2;
}


body[site="global"],
body[site="accelerate"],
body[site="connect"],
body[site="thrive"]{
  .widget.quotes .inner .swiper-slide:not(.swiper-slide-active) .item::before { opacity: .35; background: url('data:image/svg+xml,<svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.4897 5.52125C27.0796 5.41625 26.0296 6.57125 26.0296 10.9812C30.2296 10.9812 32.5396 12.9762 32.5396 17.2812C32.5396 22.0062 30.3347 24.0012 25.6096 24.0012C20.5697 24.0012 18.2597 21.9012 18.2597 16.7562C18.2597 2.16125 24.1396 -0.463755 31.4897 0.0612506V5.52125ZM0.514648 16.7562C0.514648 2.16125 6.28965 -0.463755 13.6396 0.0612506V5.52125C9.33465 5.41625 8.28465 6.57125 8.28465 10.9812C12.4846 10.9812 14.7946 12.9762 14.7946 17.2812C14.7946 22.0062 12.4846 24.0012 7.86465 24.0012C2.82465 24.0012 0.514648 21.9012 0.514648 16.7562Z" fill="%23ffffff"/></svg>') no-repeat top center/contain; }
}
body[site="global"] .widget.quotes .inner .item::before { background: url('data:image/svg+xml,<svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.4897 5.52125C27.0796 5.41625 26.0296 6.57125 26.0296 10.9812C30.2296 10.9812 32.5396 12.9762 32.5396 17.2812C32.5396 22.0062 30.3347 24.0012 25.6096 24.0012C20.5697 24.0012 18.2597 21.9012 18.2597 16.7562C18.2597 2.16125 24.1396 -0.463755 31.4897 0.0612506V5.52125ZM0.514648 16.7562C0.514648 2.16125 6.28965 -0.463755 13.6396 0.0612506V5.52125C9.33465 5.41625 8.28465 6.57125 8.28465 10.9812C12.4846 10.9812 14.7946 12.9762 14.7946 17.2812C14.7946 22.0062 12.4846 24.0012 7.86465 24.0012C2.82465 24.0012 0.514648 21.9012 0.514648 16.7562Z" fill="%23b8b43c"/></svg>') no-repeat top center/contain; }
body[site="accelerate"] .widget.quotes .inner .item::before { background: url('data:image/svg+xml,<svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.4897 5.52125C27.0796 5.41625 26.0296 6.57125 26.0296 10.9812C30.2296 10.9812 32.5396 12.9762 32.5396 17.2812C32.5396 22.0062 30.3347 24.0012 25.6096 24.0012C20.5697 24.0012 18.2597 21.9012 18.2597 16.7562C18.2597 2.16125 24.1396 -0.463755 31.4897 0.0612506V5.52125ZM0.514648 16.7562C0.514648 2.16125 6.28965 -0.463755 13.6396 0.0612506V5.52125C9.33465 5.41625 8.28465 6.57125 8.28465 10.9812C12.4846 10.9812 14.7946 12.9762 14.7946 17.2812C14.7946 22.0062 12.4846 24.0012 7.86465 24.0012C2.82465 24.0012 0.514648 21.9012 0.514648 16.7562Z" fill="%238ca2d0"/></svg>') no-repeat top center/contain; }
body[site="connect"] .widget.quotes .inner .item::before { background: url('data:image/svg+xml,<svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.4897 5.52125C27.0796 5.41625 26.0296 6.57125 26.0296 10.9812C30.2296 10.9812 32.5396 12.9762 32.5396 17.2812C32.5396 22.0062 30.3347 24.0012 25.6096 24.0012C20.5697 24.0012 18.2597 21.9012 18.2597 16.7562C18.2597 2.16125 24.1396 -0.463755 31.4897 0.0612506V5.52125ZM0.514648 16.7562C0.514648 2.16125 6.28965 -0.463755 13.6396 0.0612506V5.52125C9.33465 5.41625 8.28465 6.57125 8.28465 10.9812C12.4846 10.9812 14.7946 12.9762 14.7946 17.2812C14.7946 22.0062 12.4846 24.0012 7.86465 24.0012C2.82465 24.0012 0.514648 21.9012 0.514648 16.7562Z" fill="%23e58930"/></svg>') no-repeat top center/contain; }
body[site="thrive"] .widget.quotes .inner .item::before { background: url('data:image/svg+xml,<svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.4897 5.52125C27.0796 5.41625 26.0296 6.57125 26.0296 10.9812C30.2296 10.9812 32.5396 12.9762 32.5396 17.2812C32.5396 22.0062 30.3347 24.0012 25.6096 24.0012C20.5697 24.0012 18.2597 21.9012 18.2597 16.7562C18.2597 2.16125 24.1396 -0.463755 31.4897 0.0612506V5.52125ZM0.514648 16.7562C0.514648 2.16125 6.28965 -0.463755 13.6396 0.0612506V5.52125C9.33465 5.41625 8.28465 6.57125 8.28465 10.9812C12.4846 10.9812 14.7946 12.9762 14.7946 17.2812C14.7946 22.0062 12.4846 24.0012 7.86465 24.0012C2.82465 24.0012 0.514648 21.9012 0.514648 16.7562Z" fill="%23d36a54"/></svg>') no-repeat top center/contain; }