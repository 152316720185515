
@keyframes loadsmooth {
  0% { max-height: 0; opacity: 0; }
  75% { opacity: 1; }
  100% { max-height: 1000vh; opacity: 1; }
}
@keyframes loadsmooth2 {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes loadsmooth3 {
  0% { opacity: 0; max-height: 0; }
  75% { max-height: 1000vh; }
  100% { opacity: 1; max-height: 1000vh; }
}