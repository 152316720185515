.filter-component.filters{
    display: flex;
    gap: $space/2;

    @include mobile {
        width: 100%;
        justify-content: space-between;
    }

    .clear{
        color: $background;
        display: block;
        text-decoration: underline;
        min-width: fit-content;
        &:hover{
            cursor:pointer ;
        }
    }
}
.filters-wrapper{
    display: flex;
        flex-wrap: wrap;
        @include desktop{
            gap: $space $space/4;
            &.title{
                justify-content: end;
            }
            &:not(.title){
                .clear{
                    order: 1;
                }
            }

        }
        @include mobile{
            gap: $space;
            background-color: $white;
            padding: $space/2;
            border-radius: $radius;
        }
        &.mobile{
            margin-top: $space;
        }
}

.filters-wrapper .checkbox-label { 
  width: max-content;
  max-width: 15rem;
}