@include mobile {
  .webbox:has( > .article:first-child) { 
    margin-top: -(2rem);
  }
  
  .webbox > .article:first-child {
    padding-top: $space*3.5;
  }
}

.widget.article{
    background-color: $white; 
    @include desktop { 
      border-radius: $radius;
    }
    
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $widthm;
    background: $white;
    padding: $baseline $baseline $space*2;
    @include desktop {
      padding: $baseline $baseline $space*2;
      gap: $baseline*2;
    }
    @include mobile {
      padding: $baseline $space/2 $space*2;
      gap: $baseline;
    }


    .breadcrumb {
      margin-top: -($baseline/2);
      //@include mobile { opacity: 0; font-size: 0px; }
      font-size: $ono * 16; line-height: $ono * 20; 
      a[href*="home"] { text-transform: capitalize; }
      &, a { color: $highlight1; }
      a { white-space: nowrap; }
      span { display: inline-block; margin: 0 $baseline/2;  }
      a:hover { font-weight: bold; text-decoration: underline; }
    }
    
    & > *:first-child:not(.start) { 
      padding-top: ($space*2)-($baseline); 
    }

    .starter{
      width: 100%;
      color: $white;
      height: $ono*240;
      background: $patternmed; 
      @include desktop { 
        padding: $space;
        border-radius: $radius - $baseline/2;
      }
      @include mobile {
        padding: $space $space/2;
        border-radius: $radius;
        min-height: $ono*240;
        height: auto;
      }
      
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      
      h1 { 
        margin-top: $baseline*2;
        font-family: 'base', sans-serif;
        font-weight: bold;
        text-transform: none;
        line-height: 1em;
        @include desktop { 
          font-size: 42*$ono;
        }
        @include mobile {
          font-size: 32*$ono;
        }
      }
      h2 { 
        margin-top: $baseline/4;
        @include desktop { 
          font-size: 24*$ono;
        }
        @include mobile {
          font-size: 19*$ono;
        }
      }
      

    }
    
    .starter + .paragraph p:first-child { 
      @include desktop { 
        font-size: 24*$ono;
        padding: $baseline 0;
      }
      @include mobile {
        font-size: 19*$ono;
      }
    }
    .paragraph,
    .highlightParagraph,
    .quote {
      a { color: $highlight3; text-decoration: underline; }
    }
    
    .paragraph {
      max-width: 100%;
      width: $widthxs;
      p {
        margin-bottom: $baseline;
        &:last-child {
            margin-bottom: 0;
        }
      }
    }
    
    .highlightParagraph{
        background-color: $highlight2;
        padding: $baseline;
        border-radius: 1rem;
        max-width: 100%;
        width: $widthxs + ($baseline*2);
    }
    
    .highlightParagraph,.paragraph{
        margin: 0;
    }
    
    .quote{
        max-width: 100%;
        width: ($widths/2);
        margin: 0;
        @include transition;
        border-radius: $radius;
        @include desktop {
          font-size: $ono*24;
        }
        @include mobile {
          font-size: $ono*19;
        }
        
        &::before {
          display: block;
          content: ''; 
          width: $ono*32; height: $space*2;
          color: rgba(255,255,255,.25);
          font-size: $ono*80;
          line-height: 1em;
          margin-bottom: -.25em;
        }

        h6 { 
          font-weight: normal; 
          font-size: 16*$ono;
          margin-bottom: $baseline;
        }
        
    }
    
    .image { 
      @include desktop {  
        object-fit: contain; width: 100%;
        
        &.width100 { max-width: $widths; }
        &.width75 { max-width: $widths/1.5; }
        &.width50 { max-width: $widths/2; }
        &.width25 { max-width: $widths/4;  }
        &.height100 { max-height: 90vh;  }
        &.height75 { max-height: 75vh; }
        &.height50 { max-height: 50vh; }
        &.height25 { max-height: 25vh; }
        
      }
      @include mobile { max-width: 100%; }
    }
    
    .video{
        margin: auto;
        position: relative;
        
        @include desktop { 
          width: $widths;
          height: ($widths / (16/9));
        }
        @include mobile { 
          width: 100%;
          padding-top: calc(100%/(16/9));
          margin: 0 $space/2;
          //width: 100%; //height: calc(100vw/(16/9));
        }
    }
    
    p ol, 
    p ul {
      margin-left: 2.5rem;
      padding-left: 0;
    }
}



body[site="global"] .article .quote::before { background: url('data:image/svg+xml,<svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.4897 5.52125C27.0796 5.41625 26.0296 6.57125 26.0296 10.9812C30.2296 10.9812 32.5396 12.9762 32.5396 17.2812C32.5396 22.0062 30.3347 24.0012 25.6096 24.0012C20.5697 24.0012 18.2597 21.9012 18.2597 16.7562C18.2597 2.16125 24.1396 -0.463755 31.4897 0.0612506V5.52125ZM0.514648 16.7562C0.514648 2.16125 6.28965 -0.463755 13.6396 0.0612506V5.52125C9.33465 5.41625 8.28465 6.57125 8.28465 10.9812C12.4846 10.9812 14.7946 12.9762 14.7946 17.2812C14.7946 22.0062 12.4846 24.0012 7.86465 24.0012C2.82465 24.0012 0.514648 21.9012 0.514648 16.7562Z" fill="%23b8b43c"/></svg>') no-repeat top center/contain; }
body[site="accelerate"] .article .quote::before { background: url('data:image/svg+xml,<svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.4897 5.52125C27.0796 5.41625 26.0296 6.57125 26.0296 10.9812C30.2296 10.9812 32.5396 12.9762 32.5396 17.2812C32.5396 22.0062 30.3347 24.0012 25.6096 24.0012C20.5697 24.0012 18.2597 21.9012 18.2597 16.7562C18.2597 2.16125 24.1396 -0.463755 31.4897 0.0612506V5.52125ZM0.514648 16.7562C0.514648 2.16125 6.28965 -0.463755 13.6396 0.0612506V5.52125C9.33465 5.41625 8.28465 6.57125 8.28465 10.9812C12.4846 10.9812 14.7946 12.9762 14.7946 17.2812C14.7946 22.0062 12.4846 24.0012 7.86465 24.0012C2.82465 24.0012 0.514648 21.9012 0.514648 16.7562Z" fill="%238ca2d0"/></svg>') no-repeat top center/contain; }
body[site="connect"] .article .quote::before { background: url('data:image/svg+xml,<svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.4897 5.52125C27.0796 5.41625 26.0296 6.57125 26.0296 10.9812C30.2296 10.9812 32.5396 12.9762 32.5396 17.2812C32.5396 22.0062 30.3347 24.0012 25.6096 24.0012C20.5697 24.0012 18.2597 21.9012 18.2597 16.7562C18.2597 2.16125 24.1396 -0.463755 31.4897 0.0612506V5.52125ZM0.514648 16.7562C0.514648 2.16125 6.28965 -0.463755 13.6396 0.0612506V5.52125C9.33465 5.41625 8.28465 6.57125 8.28465 10.9812C12.4846 10.9812 14.7946 12.9762 14.7946 17.2812C14.7946 22.0062 12.4846 24.0012 7.86465 24.0012C2.82465 24.0012 0.514648 21.9012 0.514648 16.7562Z" fill="%23e58930"/></svg>') no-repeat top center/contain; }
body[site="thrive"] .article .quote::before { background: url('data:image/svg+xml,<svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.4897 5.52125C27.0796 5.41625 26.0296 6.57125 26.0296 10.9812C30.2296 10.9812 32.5396 12.9762 32.5396 17.2812C32.5396 22.0062 30.3347 24.0012 25.6096 24.0012C20.5697 24.0012 18.2597 21.9012 18.2597 16.7562C18.2597 2.16125 24.1396 -0.463755 31.4897 0.0612506V5.52125ZM0.514648 16.7562C0.514648 2.16125 6.28965 -0.463755 13.6396 0.0612506V5.52125C9.33465 5.41625 8.28465 6.57125 8.28465 10.9812C12.4846 10.9812 14.7946 12.9762 14.7946 17.2812C14.7946 22.0062 12.4846 24.0012 7.86465 24.0012C2.82465 24.0012 0.514648 21.9012 0.514648 16.7562Z" fill="%23d36a54"/></svg>') no-repeat top center/contain; }