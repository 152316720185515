.widget.cta {
    .inner:not(:first-child){
        margin-top: $space;
    }
    .inner {
        position: relative;
        &.full,
        &.center {
          & > * { z-index: 2; }
          &::before {
            content: ''; display: block;
            position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 1;
            background: #1e1e1e; opacity: .5; 
          }
        }
        &.full {
            width: 100%;
            height: $ono*550;
        }

        &.center {
            overflow: hidden;
            @include desktop { 
              border-radius: $radius;
              width: $widthl; 
            }
            height: calc($widthl/(21/9));
        }

        &.full,
        &.center {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: $space/4;
            background-size: cover;
            background-position: center center;

            h2 {
                color: $highlight2;
                line-height: 1em;
                text-transform: uppercase;
                max-width: 550*$ono;
                font-family: 'heading', sans-serif;
                font-weight: bold;
            }

            p {
                max-width: $ono*500;
                color: $white;
                margin: $space/4 0 $space;
            }
            
            @include mobile {
                  padding: $space/2;
                  align-items: center;
                  h2, p { text-align: center; }
                  h2 { font-size: $ono * 42; }
            }
            
            @include desktop {
                  h2 { font-size: $ono * 60; }
                  &.text-left{
                      align-items: start;
                      padding-left: $space*4;
                  }
                  &.text-right{
                      align-items: end;
                      padding-right: $space*4;
                      
                      h2, p{
                        text-align: right;
                      }
                  }
                  &.text-center{
                  align-items: center;
                      h2{
                          text-align: center;
                      }
                      p{
                          text-align: center;
                      }
                  }
            }
        }

        &.single {
            @include desktop {
              max-width: 100%;
              width: $widthm;
              padding: $space*1.5 $space*3;
              gap: $space;
            }
            
            @include mobile {
              margin: $space $space/2;
              padding: $space*1.5 $space/2;
              gap: $baseline;
            }
            border-radius: $radius;
            display: flex;
            flex-direction: column;
            align-items: start;
            color: $background;

            h2 {
                font-size: $ono*42;
                @include mobile { line-height: 1.125em }
            }
            
            background: $patternbig;

        }
    }
}