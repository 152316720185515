/* New */
section.widget { 
  padding: 0;  
  @include desktop {
    margin: $space*3 auto;
  }
  @include mobile {
    margin: $space auto;
  }
  @include transition;
  transition-delay: .025s; 
  
  &:not(.start):not(.headsplit),
  &:not(.start):not(.headsplit) [ani] { 
    opacity: 0; 
    filter: blur($space/2);
  }
  
  &.start:not(.headsplit),
  &.start:not(.headsplit) [ani] { 
    opacity: 1; 
  }
  
  [ani] {
   transition: all .125s ease-in;
  }
  
  
  @for $i from 1 through 30 {
    &.start [ani]:nth-child(#{$i}) {
      transition-delay: $i * .125s;
    }
  }  
}


@include mobile {
  .mobileno { display: none!important; }
}



/* Widgets */ 

/*
.widget .inner { padding: 1.5rem; }
.widget.maintitle { background: rgba(0,0,0,.5); color: white;}
.widget.subtitle { background: blue; }
.widget.paraaf { background: green; }
.widget.imagebox { background: pink; }
.widget.multitest { background: yellow; }

.widget.maintitle h2 { padding: 0 1.5rem; }
*/