/* imageslider */
.imageslider:not(:first-child) img{ border-bottom-left-radius: 1rem; border-bottom-right-radius: 1rem; }

.imageslider { position: relative; }
.imageslider ul,
.imageslider li { list-style: none; padding: 0; margin: 0; }
.imageslider ul.slides { position: relative; width: 100%; padding-top: 56%; }
.imageslider ul.slides li { @include transition; position: absolute; top: 0; left: 0; right: 0;  width: 100%; padding-top: 56%; z-index: 1; }
.imageslider ul.slides li:not(.active) { opacity: 0; z-index: 0; }
.imageslider ul.slides li img { position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%; object-fit: cover; }
  
.imageslider ul.dots { display: flex; justify-content: center; margin: 1rem; }
.imageslider ul.dots li { cursor: pointer; border-radius: 100%; background: black; margin: 0 .5rem; width: .75rem; height: .75rem; text-indent: -100vw; overflow: hidden; }
.imageslider ul.dots li.active { background: red; }

.imageslider .next,
.imageslider .prev { @include transition; position: absolute; top: 0; bottom: 2.75rem; z-index: 2; height: max-content; margin: auto 0; padding: 1.25rem; font-weight: bold; background: white; cursor: pointer; }
.imageslider .next { right: 0; border-top-left-radius: 10rem; border-bottom-left-radius: 10rem; }
.imageslider .prev { left: 0; border-top-right-radius: 10rem; border-bottom-right-radius: 10rem; }
.imageslider .next { padding: 1.25rem 0 1.25rem 1.25rem; }
.imageslider .prev { padding: 1.25rem 1.25rem 1.25rem 0; }
.imageslider .prev:hover,
.imageslider .next:hover { padding: 1.25rem; }

.imageslider li { position: relative; }
.imageslider li .overlay {
  position: absolute; top: 0; right: 0; bottom: 0; left: 0; 
  width: max-content; height: max-content; margin: auto; 
  padding: 3rem; 
  z-index: 1; 
}
.imageslider li .overlaylink { 
  position: absolute; top: 0; right: 0; bottom: 0; left: 0; 
  width: 100%; height: 100%;
  z-index: 2; 
  text-indent: -200vh; overflow: hidden;
}
.imageslider li .overlaylink:hover { background: rgba(0,0,0,.2); }
