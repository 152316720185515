section.form {
    .inner { 
      max-width: $widthm; 
      display: flex;
      border-radius: $radius; 
      overflow: hidden;
      & > * { 
        width: 50%; 
        @include transition;
      }
    }
    
    h3 { font-size: 42*$ono; margin-bottom: $space; }
    p { font-size: 24*$ono; font-weight: normal; }

    .left{
        background-color: $highlight2;
        padding: $space*2 $space;
    }
    .right{
        background: $highlight1;
        padding: $space*2 $space;

        .forms-options{
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }
}


section.detailcard,
section.formnormal {
    .inner { 
      @include mobile {
        margin: $space/2;
        
        h3 { font-size: 28*$ono; margin-bottom: $space/2; }
        p { font-size: 19*$ono; font-weight: normal; }
      }
      @include desktop { 
        max-width: $widthm; 
        display: flex;
  
        h3 { font-size: 42*$ono; margin-bottom: $space; }
        p { font-size: 24*$ono; font-weight: normal; }
        
        & > * { 
          width: 50%; 
          @include transition;
        }
      }
      border-radius: $radius; 
      overflow: hidden;

    }
    

    .left{
        background-color: $highlight1;
        color: $white; 
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-content: stretch;
        

        @include mobile {
          .text { padding: $space*1.5 $space/2; }
        }
        
        @include desktop {
          .text { padding: $space*2 $space; }
          
          &::after { 
            content: ' ';
            display: block;
            background: $pattern;
            width: 100%;
            height: 100%;
          }
        }
    }
    .right{
        background: $highlight2;
        
        @include desktop { 
          padding: $space*2 $space;
          .forms-options{
              display: flex;
              flex-direction: column;
              gap: 1rem;
          }
          .form-text p { font-size: $ono*14; }
        }
        
       @include mobile {
         .form-component {
            padding: $space $space/2; 
         }
         .form-row { display: block; }
         .field-wrapper { width: 100%; 
           &:first-child { margin-bottom: $space/2; }
         }
         .form-component .form-row:not(:first-child) p { margin-top:  -($baseline/2); padding: 0 0 ($baseline/2); }
         .form-text p { font-size: $ono*14; }
         .checkbox-label { font-size: $ono*14; }
          &::after { 
            content: ' ';
            display: block;
            background: $patternmobile;
            width: 100%;
            height: $ono*125;
          }
        }
    }
}