.widget.detailcard {
  .right {
    .body {
      @include mobile {
        padding: $space/2;
      }
      ul {
        list-style: none;
      }

      ul.list {
        @include desktop { margin-bottom: $space; }

        li {
          padding: $space/2 0;
          color: $background;
          p:not(:last-child) { padding-bottom: $baseline; }
          &:not(:first-child) {
            border-top: $highlight1 solid 1px;
          }
        }
      }
      h4 {
        font-size: $ono*16;
        margin-bottom: $baseline/8;
      }

      p {
        font-size: $ono*16;
      }

      .tags {
        h4 {
          margin-bottom: $space/2;
          color: $background;
        }

        ul {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: $space/2;
        }

        li {
          border: 1px solid black;
          border-radius: $radius;
          padding: $space/6 $space/3;
          text-align: center;
          border-color: $background;
          a{
            color: $background;
          }
        }
      }
    }

    button{
      margin-top: $space;
    }
    .form{
      @include transition;
      .back{
        display: block;
        text-decoration: underline;
        color: $highlight1;
        margin-top: $space*2;
        font-weight: 700;
        @include mobile{
          margin: 0 0 $space $space/2;
        }
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}






/* old
  .widget.detailcard {
  .inner {
    max-width: $widthm;
    display: flex;
    border-radius: $radius;
    overflow: hidden;

    &>* {
      width: 50%;
      @include transition;
    }
  }

  h3 {
    font-size: 42*$ono;
    margin-bottom: $space;
  }

  p {
    font-size: 24*$ono;
    font-weight: normal;
  }

  .left {
    background-color: $highlight1;
    color: $white; 

    .text {
      padding: $space*2 $space;
    }

    &::after { 
      content: ' ';
      display: block;
      background: $pattern;
      width: 100%;
      height: 100%;
    }
  }

  .right {
    background: $highlight2;
    padding: $space*2 $space;

    .body {
      ul {
        list-style: none;
      }

      ul.list {
        margin-bottom: $space*4;

        li {
          padding: $space/2 0;
          color: $background;
          &:not(:first-child) {
            border-top: $highlight1 solid 1px;
          }
        }
      }
      h4 {
        font-size: $ono*16;
        margin-bottom: $baseline/8;
      }

      p {
        font-size: $ono*16;
      }

      .tags {
        h4 {
          margin-bottom: $space/4;
          color: $background;
        }

        ul {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: $space/2;
        }

        li {
          border: 1px solid black;
          border-radius: $radius;
          padding: $space/6 $space/3;
          text-align: center;
          a{
            color: black;
          }
        }
      }
    }

    button{
      margin-top: $space;
    }
    .form{
      @include transition;
      .back{
        display: block;
        text-decoration: underline;
        color: $highlight1;
        margin-top: $space*2;
        font-weight: 700;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}
*/