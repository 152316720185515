.widget.itemlist {

    .inner {
        display: flex;
        flex-direction: column;
        background: $highlight2;
        border-radius: $radius;
        @include desktop{
            width: $widthl;
            padding: $space*2;
        }
        @include mobile{
            margin: 0 $space/2;
            padding: $space;
        }
        > button{
            margin: 0 auto;
        }

        .top{
            display: flex;
            justify-content: space-between;
            margin-bottom: $baseline; 
            @include mobile{
                flex-direction: column;
                margin: 0;
                gap: $space;
            }

            h2{
                color: $background;
                @include desktop {
                    font-size: 32*$ono;
                    margin-right: $space*10;
                }
                @include mobile{
                    margin-right: $space*4;
                }
            }
            &.no-margin{
                margin-bottom: 0;
            }
        }

        .searchbar{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60*$ono;
            &.active{
                margin-bottom: $space*2;
            }
            .searchinput{
                width: 400*$ono;
                height: 100%;
                outline: none;
                border: none;
                border-radius: $radius 0 0 $radius;
                padding: 0 0 0 $space;
                @include mobile{
                    width: 60%;
                }
            }
            .button{
                height: 100%;
                border-radius: 0 $radius $radius 0;
                @include mobile{
                    width: 40%;
                    font-size: 12*$ono;
                }
            }
        }
    }
    .slides {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        @include mobile{
            display: flex;
            flex-direction: column;
        }
        margin: $space 0;
        gap: $space*1.5;
    }
}