section.fullpattern {
  width: 100%; height: 15rem;
  overflow: hidden; text-indent: -200vw; 
   
  @include desktop { 
    background: $pattern; 
  }
  @include mobile { 
    background: $patternmobile; 
  }
  transition-delay: 0s;
}