.widget.logoslider{
    .inner{ 
      max-width: 100%; 
      width: $widthm;
    }
    
    .fullinner {} 
    
      h3{
        color: $white;
        @include desktop {
          margin-bottom: $space/2;
        }
        @include mobile {
          margin: $space/4 0;
        }
      }

    .swiper-wrapper {
        @include desktop {
          transition-timing-function: linear;
          align-items: center;
        }
        
        @include mobile { 
          
        }
    }

    .item{
        display: block;
        margin: auto;
        
        @include desktop {
          width: 100*$ono;
        }
    }
    
    @include mobile {
      .logobarmobile {
        text-align: center;
      }
      .mobileitem {
        max-width: 25%; margin: 0 10% $baseline;
        height: 5rem;
        object-fit: contain;
      }
    }
}