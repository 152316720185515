/// new
header {
  position: fixed; top: 0; right: 0; left: 0; z-index: 5000; margin: auto;
  backdrop-filter: blur($baseline); -webkit-backdrop-filter: blur($baseline);
  background: $backgroundO;
  
  .sdesktop,
  .smobile { display: none; }
  

  h2.sitename { display: none; }
  
  &, nav { @include transition; }
  
  img { @include transition; }
  img:not([src]) { opacity: 0; }
  
  img[src*=global] { 
    max-width: 11rem;
    @include mobile { height: $baseline*2; }
  }
  
  nav li {
    a[href='#'],
    a[href='/'] { pointer-events: none; }
  }
  
  .search {
    &, a { overflow: hidden; text-indent: -100vh; display: block; 
      &:hover { text-decoration: none; }
    }

    width: $ono*25; height: $ono*25;
    
    @include desktop { 
      //margin-top: -($baseline/6);
      &.smobile { display: none; } 
    }
    @include mobile { 
      width: $baseline;
      position: absolute;
      top: -3.75rem; right: 3.5rem;
      height: $space*2;
      
      &.sdesktop { display: none; } 
    }
  }
  
  nav { position: relative; }
  
  @include mobile {
    .search:not(:hover) + div + ul + a + .searchbar:not(:hover){ 
      opacity: 0; pointer-events: none;
    }
    
    .search:hover,
    .searchbar:hover { opacity: 1; pointer-events: all; }
  }
  
  @include desktop {
    .search:not(:hover) + .searchbar:not(:hover) { 
      opacity: 0; pointer-events: none;
    }
  }

  .searchbar {
    @include transition;
    position: absolute;
    @include desktop { top: $baseline*1.5; right: 0; }
    @include mobile { top: -.5rem; right: 0; }
    background: $highlight1; border-radius: $radius*2;
    padding: $ono*4;
    display: flex;
    box-shadow: 0 0 $baseline $backgroundO;
    
    input[type='search'], 
    button.darken { 
      &, &:hover { padding: $baseline/2 $baseline!important; }
    }
    
    input:focus { outline: 0; }
    
    input[type='search'] { 
      border: 0; border-top-left-radius: $radius; border-bottom-left-radius: $radius; 
      box-shadow: inset 0 0 $baseline rgba(0,0,0,.125);
    }
    
    button.darken { text-overflow: inherit; overflow: inherit;
      border-top-left-radius: 0; border-bottom-left-radius: 0; 
      &:hover { padding-left: auto; padding-right: auto; }
    }
  }
  
  
  @include mobile { 
    padding: $space/2;
    border-bottom-left-radius: $radius; border-bottom-right-radius: $radius;
    
    &:not(.open) { 
      height: $ono*85; 
    }
    
    //.inner { display: flex; flex-direction: row; justify-content: space-between; align-items: center; }
    
    &:not(:has(ul:empty)) + .webbox { margin-top: $ono*85; }
    
    .hamburger { 
      @include transition;
      position: fixed; top: $space; right: $baseline; 
      overflow: hidden; text-indent: 100vw;
      width: $ono*25; height: $ono*18;
    }
    
    &:not(.open) nav ul { max-height: 0; opacity: 0; pointer-events: none; padding: 0; }
    
    &.open {
      background: $white; height: 100vh; opacity: 1; overflow-y: scroll;
      a { color: $black; }
      .hamburger { transform: rotate(180deg); }
      img { filter: invert(1) sepia(90%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(227%); }
    }
    
    nav > ul {
      padding: $baseline 0;
    }
    
    li {
      list-style: none; 
    }
    
    nav > ul > li { 
      padding: $space/2 0; 
      position: relative;
      
      a.active,
      a:hover { color: $background; border-bottom: $ono solid $background; }
      
      .submenu li { padding: 0.125rem 0 0.25rem 0; }
      .submenu ul:not(:has(li + li)) { 
        padding: 0 ($baseline/2);
      }
            
      //&:has(.submenu):hover::after,
      &:has(.submenu).open::after { transform: rotate(180deg); }
      
      &:has(.submenu)::after {
        @include transition;
        content: ''; display: block;
        width: $space; height: $space; 
        position: absolute; top: $space/4; right: $space/8; 
        
        background: url('data:image/svg+xml,<svg width="40" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L13.0924 14.8146C13.1434 14.8729 13.206 14.9196 13.2762 14.9515C13.3464 14.9835 13.4225 15 13.4995 15C13.5764 15 13.6525 14.9835 13.7227 14.9515C13.7929 14.9196 13.8555 14.8729 13.9065 14.8146L26 1" stroke="%23000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') center center no-repeat;
      }
      
      & > a { 
        font-family: 'base', sans-serif;
        font-size: $ono*25; line-height: $ono*28; 
      }
      
      &:hover { max-height: 1000vh; }
            

//      &:has(.submenu:hover li a:hover) .submenu,
      //&:not(:hover) .submenu,
      &:not(.open) .submenu { 
        max-height: 0; opacity: 0; pointer-events: none;
      }
      
      a[href='/'], a[href='#'] { pointer-events: none; }
      
      .submenu { 
        @include transition; max-height: 1000vh;
        font-size: $ono*16; line-height: $ono*24;
        padding-top: $baseline/2; 
        ul { padding: $baseline/2; }
        li:first-child {
          &:has(+ li) { text-transform: uppercase; }
          a { color: $black; }
          a[href='/'],
          a[href='#'] { color: $grey; pointer-events: none; }
          a[href$='/home'] { color: #444f22; }
          a[href$='/accelerate/home'] { color: #1a464e; }
          a[href$='/connect/home'] { color: #832818; }
          a[href$='/thrive/home'] { color: #4d1825; }
          
          
          
                a[href$='/accelerate/home'] { background: #8ca2d0; 
                  &:hover { background: #1a464e; } 
                }
                a[href$='/connect/home'] { background: #e58930;
                  &:hover { background: #832818; } 
                }
                a[href$='/thrive/home'] { background: #d36a54; 
                  &:hover { background: #4d1825; } 
                }
                
                a[href$='/accelerate/home'],
                a[href$='/connect/home'],
                a[href$='/thrive/home'] {
                  display: inline-block;
                  color: $white; border-radius: $radius; 
                  padding: $baseline/4 $baseline/2; margin-bottom: 0; 
                  margin-left: -($baseline/2); 
                  &:hover { text-decoration: none; border: 0; }
                }

          
        } 
      }
    }
  }
  
  @include desktop {  
        &:has(ul:empty) .sdesktop {
          opacity: 0;
        }
        
        .sdesktop { 
          @include transition;
        }

        &:has(ul:empty) + .webbox { margin-top: 0; min-height: 100vh; }
        &:not(:has(ul:empty)) + .webbox { margin-top: $ono*120; }
        
        img { height: $ono * 49; }
      
        .inner { 
          padding: 0; 
          display: flex; justify-content: space-between; align-items: center;
          width: $maxwidth; 
        }

    
     
        nav { 
          display: flex; align-items: center;
          height: $ono * 120; 
          font-size: $ono * 14; 
          color: $white;
          //padding: 48*$ono 0;
          
          a:hover,
          .active { color: $white; border-bottom: $ono solid $highlight1; }
          
          .hamburger { display: none; }
          
          ul, li { list-style: none; }
          
          ul { display: flex; }
 
          li {
            padding: 0 $baseline;
            position: relative;
           }
        }
     
        li {
          &:not(:hover) .submenu { 
            opacity: 0; pointer-events: none; 
            .wrapper { box-shadow: 0 0 0 $backgroundO0; }
          }
        }
        
        
        .submenu{
          @include transition;
          position: absolute;
          
          .wrapper{
            display: flex;
            flex-direction: column;
            margin-top: $space; padding: $space 0;
            background-color: $white;
            border-radius: $radius;
            left: 50%; transform: translate(-20%,0);
            box-shadow: 0 $baseline $space $backgroundO;
          }
          
          ul {
            &:not(:first-child) li:first-child:has(+ li) {
                padding-top: $baseline;
            }
            flex-direction: column;
            &:not(:first-child){ /* border-left: 2px solid $lightgrey; */ }
            li { 
              min-width: $widths/6;
              padding: $baseline/4 $space; 
              line-height: 1.25em; 
              
              &:first-child:has(+ li) {
                padding-bottom: $baseline/4; 
                a { color: $background; text-transform: uppercase; }
                a[href='/'],
                a[href='#'] { color: $grey; pointer-events: none; }
                
                a[href$='/home'] { color: #444f22; }
                
                a[href$='/accelerate/home'] { color: #1a464e; }
                a[href$='/connect/home'] { color: #832818; }
                a[href$='/thrive/home'] { color: #4d1825; }
                
                a[href$='/accelerate/home'] { background: #8ca2d0; 
                  &:hover { background: #1a464e; } 
                }
                a[href$='/connect/home'] { background: #e58930;
                  &:hover { background: #832818; } 
                }
                a[href$='/thrive/home'] { background: #d36a54; 
                  &:hover { background: #4d1825; } 
                }
                
                a[href$='/accelerate/home'],
                a[href$='/connect/home'],
                a[href$='/thrive/home'] {
                  display: inline-block;
                  color: $white; border-radius: $radius; 
                  padding: $baseline/4 $baseline/2; margin-bottom: -($baseline/4); 
                  margin-left: -($baseline/2);
                  &:hover { text-decoration: none; border: 0; }
                }
                
                
              }
              
              a { color: $black;  white-space: nowrap; }
            }
          }
      }
  }
    

  
  a { 
    @include transition; 
    text-decoration: none; color: $white; 
    font-family: 'base2', sans-serif; font-weight: 100;
  }
}

@include mobile {
  body:not(.scrolling) header:not(.open) {
    background: $background;
  }
}

body.scrolling {
  header {
    box-shadow: 0 0 $space $backgroundO;
    nav {
      height: $ono * 90; 
      //padding: 24*$ono 0;
    }
    
    .searchbar { 
      @include desktop { top: 1.25rem; }
    }
  }
}



body[site="global"] header .hamburger {
  background: url('data:image/svg+xml,<svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1H26" stroke="%23b8b43c" stroke-width="1.5" stroke-linecap="round"/><path d="M1 10H26" stroke="%23b8b43c" stroke-width="1.5" stroke-linecap="round"/><path d="M1 19H26" stroke="%23b8b43c" stroke-width="1.5" stroke-linecap="round"/></svg>') no-repeat center center/cover;
}
body[site="accelerate"] header .hamburger {
  background: url('data:image/svg+xml,<svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1H26" stroke="%238ca2d0" stroke-width="1.5" stroke-linecap="round"/><path d="M1 10H26" stroke="%238ca2d0" stroke-width="1.5" stroke-linecap="round"/><path d="M1 19H26" stroke="%238ca2d0" stroke-width="1.5" stroke-linecap="round"/></svg>') no-repeat center center/cover;
}
body[site="connect"] header .hamburger {
  background: url('data:image/svg+xml,<svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1H26" stroke="%23e58930" stroke-width="1.5" stroke-linecap="round"/><path d="M1 10H26" stroke="%23e58930" stroke-width="1.5" stroke-linecap="round"/><path d="M1 19H26" stroke="%23e58930" stroke-width="1.5" stroke-linecap="round"/></svg>') no-repeat center center/cover;
}
body[site="thrive"] header .hamburger {
  background: url('data:image/svg+xml,<svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1H26" stroke="%23d36a54" stroke-width="1.5" stroke-linecap="round"/><path d="M1 10H26" stroke="%23d36a54" stroke-width="1.5" stroke-linecap="round"/><path d="M1 19H26" stroke="%23d36a54" stroke-width="1.5" stroke-linecap="round"/></svg>') no-repeat center center/cover;
}



body[site="global"] header.open .hamburger {
  background: url('data:image/svg+xml,<svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1H26" stroke="%23BBBBBB" stroke-width="1.5" stroke-linecap="round"/><path d="M1 10H26" stroke="%23444f22" stroke-width="1.5" stroke-linecap="round"/><path d="M1 19H26" stroke="%23BBBBBB" stroke-width="1.5" stroke-linecap="round"/></svg>') no-repeat center center/cover;
}
body[site="accelerate"] header.open .hamburger {
  background: url('data:image/svg+xml,<svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1H26" stroke="%23BBBBBB" stroke-width="1.5" stroke-linecap="round"/><path d="M1 10H26" stroke="%231a464e" stroke-width="1.5" stroke-linecap="round"/><path d="M1 19H26" stroke="%23BBBBBB" stroke-width="1.5" stroke-linecap="round"/></svg>') no-repeat center center/cover;
}
body[site="connect"] header.open .hamburger {
  background: url('data:image/svg+xml,<svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1H26" stroke="%23BBBBBB" stroke-width="1.5" stroke-linecap="round"/><path d="M1 10H26" stroke="%23832818" stroke-width="1.5" stroke-linecap="round"/><path d="M1 19H26" stroke="%23BBBBBB" stroke-width="1.5" stroke-linecap="round"/></svg>') no-repeat center center/cover;
}
body[site="thrive"] header.open .hamburger {
  background: url('data:image/svg+xml,<svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1H26" stroke="%23BBBBBB" stroke-width="1.5" stroke-linecap="round"/><path d="M1 10H26" stroke="%234d1825" stroke-width="1.5" stroke-linecap="round"/><path d="M1 19H26" stroke="%23BBBBBB" stroke-width="1.5" stroke-linecap="round"/></svg>') no-repeat center center/cover;
}


@include desktop {
  body header:not(.open) .search { 
    background: url('data:image/svg+xml,<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="%23ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M23.9993 24.0003L18.0703 18.0713" stroke="%23ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center center/contain;
  }
}

@include mobile {
  body[site="global"] header:not(.open) .search { 
    background: url('data:image/svg+xml,<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="%23b8b43c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M23.9993 24.0003L18.0703 18.0713" stroke="%23b8b43c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center center/contain;
  }
  
  body[site="accelerate"] header:not(.open) .search { 
    background: url('data:image/svg+xml,<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="%238ca2d0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M23.9993 24.0003L18.0703 18.0713" stroke="%238ca2d0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center center/contain;
  }
  
  body[site="connect"] header:not(.open) .search { 
    background: url('data:image/svg+xml,<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="%23e58930" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M23.9993 24.0003L18.0703 18.0713" stroke="%23e58930" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center center/contain;
  }
  
  body[site="thrive"] header:not(.open) .search { 
    background: url('data:image/svg+xml,<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="%23d36a54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M23.9993 24.0003L18.0703 18.0713" stroke="%23d36a54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center center/contain;
  }
  
  body header.open .search { 
    background: url('data:image/svg+xml,<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="%23000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M23.9993 24.0003L18.0703 18.0713" stroke="%23000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center center/contain;
  }
}









