section.headsplit {
  @include mobile { margin-top: -1rem; }
  .inner { 
    position: relative;
    display: flex;
    background: $highlight2; 
    border-radius: $radius; overflow: hidden;
   @include desktop{
      width: $maxwidth;
   }
    @include mobile{
      flex-direction: column;
      margin: $space/2;
    }
  }

  .inner.no-pattern{
    @include mobile {
      .left{
        padding: 0 0 0;
        h1{
          display: flex;
          align-items: end;
          height: calc(($maxwidth / 4) - ($space*2));
          padding: 0 0 $space $space/2;
          background-size: cover;
          color: $highlight2;
          &[style*='background-image'] { box-shadow: inset 0 100vh rgba(0,0,0,.45); }
        }
        .buttons,.tags,.breadcrumb,p{
          padding: 0 $space/2;
        }
        .breadcrumb{
          &:first-child{
            display: none;
          }
          &:not(:first-child){
            position: static;
          }
        }
      }
      .right{
        display: none;
      }
    }
  }
  
  .left { 
    display: flex;
    flex-direction: column;
    @include desktop{
      padding: $space*2 $space*2 $space*2 $ono*93;
      width: $maxwidth; 
    }
    @include mobile{
      padding: $space/2 $space/2 $space*4;

    }
    
    & > *:not(:last-child):not(.breadcrumbs) { 
      margin-bottom: $baseline; 
    }
    
    & > *:not(a:has(img.logo)):last-child { margin-bottom: $space*1.5; }
    
    h1 {
      line-height: 1em;
      text-transform: uppercase; 
      color: $highlight3;
      @include desktop{
        padding-top: $space*1.5;
        font-size: $ono * 72;
        padding-bottom: $baseline;
        background-size:  0 0;
      }
      @include mobile{
        font-size: $ono*42;
        padding-top: $space/2;
        padding-bottom: 0;
        height: auto;
        background-size:  0 0;
        background-position: center center;
      }
    }
    
    p { 
      @include desktop{
        font-size: $ono * 24;
      }
      @include mobile {
        font-size: $ono*19;
        padding-right: $baseline;
      }
      line-height: $ono * 28; 
      color: $black;
      padding-bottom: $baseline;
    }
    .logo{
      
      margin-top: auto;
      height: $ono*83;
      object-position: left center;
      width: min-content;
      
      @include desktop {
        //width: $ono*120;
      }
      @include mobile{
        //width: $ono*80;
        margin: $space auto ($space*2);
      }
    }
    .buttons { 
      margin-top: -($baseline/2);
      .button { margin-top: $baseline/2; }
    }
  }
    
  
  &:not(.start) .right .img img { opacity: 0; }
  
  .right {
    position: relative; 
    
    @include desktop{
      margin-left: ($maxwidth / 4); 
      width: ($maxwidth / 2); 
    }
    
    .img { 
      //background-color: $highlight2;
      position: relative; z-index: 2;
      @include desktop{
        width: calc(($maxwidth / 2) - ($space*2));
        height: calc(($maxwidth / 2) - ($space*2));
        margin: $space*2 $space*2 $space*2 (-($maxwidth / 2)/2);
      }

      @include mobile{
        width: 80vw;
        height: 60vw;
        margin: 0 auto $space/2;
        top: - calc((($maxwidth / 4) - ($space*2))/2);
      }  
      //box-shadow: 0 $space $space rgba(0,0,0,.125);
      img { filter: drop-shadow(0 $space $space rgba(0,0,0,.125)); } 
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      
      img { 
        @include transition; 
        transition-delay: .5s;
        position: absolute;
        width: 100%; 
        height: 100%; 
        object-fit: cover;
      }
    }
    
    
    @include mobile { 
      &:not(:has(img)) .pattern {
        height: 10rem; 
        margin-top: -10rem;
      }
    }
    
    .pattern {
      @include desktop { background: $pattern; }
      @include mobile { background: $patternmobile; border-bottom-left-radius: $radius; border-bottom-right-radius: $radius; }
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: 1; 
      width: 100%; height: 100%;
    }
  }
  
  .tags { 
    margin-top: -($space/2);
    //padding-bottom: $space; 
    span{
      font-weight: bold; 
      color: $highlight3; 
    }
    a { 
      font-weight: bold; 
      color: $highlight3; 
      &:not(:first-child){
        margin-left: $space/4;
      }
    }
    
    &.extra{
      @include desktop{ font-size: $ono * 24; margin-top: -($baseline*1.25); padding-bottom: $baseline; /* margin-top: -($baseline * 2); */ }
      @include mobile { font-size: $ono*19; }
    }
  }
  
  
    
  .breadcrumb {
      a[href*="home"] { text-transform: capitalize; }
      @media desktop { position: absolute; top: $space*1.5; left: $space*1.5; }
      
      font-size: $ono * 16; line-height: $ono * 20; 
      a { white-space: nowrap; }
      &, a { color: $background; @include transition; }
      span { display: inline-block; margin: 0 $baseline/2; }
      a:hover { font-weight: bold; text-decoration: underline; }
  }
  
  
}
