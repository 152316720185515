.filter-button.btn-wrapper{
    position: relative;

    & > span{
        border: $ono solid $highlight3;
        color: $highlight3;
        padding: ($ono*10) ($ono*43) ($ono*10) ($ono*18);
        border-radius: $radius;

           @include desktop {
            &:hover + .radios-wrapper{
              visibility: visible;
              z-index: 500;
          }
           }
    }

    .radios-wrapper:hover {
        visibility: visible;
        z-index: 500;
    }

    @include mobile{
      &.open-mobile.arrowup::after{
        transform: rotate(180deg);
      }
      &.open-mobile .radios-wrapper{
        visibility: visible;
              z-index: 500;
      }
    }
    
    //span:not(:hover) + .radios-wrapper { pointer-events: none; }
    
    span:has(+ .radios-wrapper .field-input-checkbox:checked) {
        border: $ono solid $highlight3;
        color: $white;
        background: $highlight3;
    }

    .radios-wrapper{
        position: absolute;
        visibility: hidden;
        z-index: -999;
        padding: ($baseline/2)-($ono);
        left: 50%;
        transform: translate(-50%,0);
        @include mobile{
          top: $space;
          padding-top: 0;
        }
        .radios{
            display: flex;
            flex-direction: column;
            gap: $space/6;
            padding: $space/2 $space/1.5;
            border-radius: $radius;
            background-color: white;
        }
    }

}


.arrowup { 
  position: relative; 
  &::after {
    @include transition;
    display: block; content: '';
    position: absolute; top: $ono*4; right: ($ono*18); margin: auto; 
    height: $ono*12; width: $ono*22;
  }
  @include desktop{
    &:hover {
    &::after {
     transform: rotate(180deg);
    }
  }
  }
}

body[site="global"] .arrowup::after {
  background: url('data:image/svg+xml,<svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 10.418L9.77717 1.12488C9.7425 1.08568 9.69989 1.05429 9.65217 1.03279C9.60445 1.0113 9.55271 1.00018 9.50037 1.00018C9.44803 1.00018 9.39629 1.0113 9.34857 1.03279C9.30085 1.05429 9.25824 1.08568 9.22357 1.12488L1 10.418" stroke="%23444f22" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/></svg>') center right no-repeat;
}
body[site="accelerate"] .arrowup::after {
  background: url('data:image/svg+xml,<svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 10.418L9.77717 1.12488C9.7425 1.08568 9.69989 1.05429 9.65217 1.03279C9.60445 1.0113 9.55271 1.00018 9.50037 1.00018C9.44803 1.00018 9.39629 1.0113 9.34857 1.03279C9.30085 1.05429 9.25824 1.08568 9.22357 1.12488L1 10.418" stroke="%231a464e" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/></svg>') center right no-repeat;
}
body[site="connect"] .arrowup::after {
  background: url('data:image/svg+xml,<svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 10.418L9.77717 1.12488C9.7425 1.08568 9.69989 1.05429 9.65217 1.03279C9.60445 1.0113 9.55271 1.00018 9.50037 1.00018C9.44803 1.00018 9.39629 1.0113 9.34857 1.03279C9.30085 1.05429 9.25824 1.08568 9.22357 1.12488L1 10.418" stroke="%23832818" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/></svg>') center right no-repeat;
}
body[site="thrive"] .arrowup::after {
  background: url('data:image/svg+xml,<svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 10.418L9.77717 1.12488C9.7425 1.08568 9.69989 1.05429 9.65217 1.03279C9.60445 1.0113 9.55271 1.00018 9.50037 1.00018C9.44803 1.00018 9.39629 1.0113 9.34857 1.03279C9.30085 1.05429 9.25824 1.08568 9.22357 1.12488L1 10.418" stroke="%234d1825" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/></svg>') center right no-repeat;
}







body[site="global"] .arrowup:has(.field-input-checkbox:checked)::after,
body[site="accelerate"] .arrowup:has(.field-input-checkbox:checked)::after,
body[site="connect"] .arrowup:has(.field-input-checkbox:checked)::after,
body[site="thrive"] .arrowup:has(.field-input-checkbox:checked)::after {
  background: url('data:image/svg+xml,<svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 10.418L9.77717 1.12488C9.7425 1.08568 9.69989 1.05429 9.65217 1.03279C9.60445 1.0113 9.55271 1.00018 9.50037 1.00018C9.44803 1.00018 9.39629 1.0113 9.34857 1.03279C9.30085 1.05429 9.25824 1.08568 9.22357 1.12488L1 10.418" stroke="%23ffffff" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/></svg>') center right no-repeat;
}








