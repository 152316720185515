// Default
html { scroll-behavior: smooth; }
body, 
#root, 
.webbox {
  background-color: var(--background);
  transition: background-color ease-in .125s;
  
/*
  @include mobile { 
    padding: 0 $space/4;
  }
*/
}

img { border: 0; }

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.webbox { /* opacity: 1;  */ @include transition; min-height: calc(100vh - (120*$ono)); }
.webbox.search-box{@include mobile {margin-top: $ono*85;}}
.webbox.fade-out { opacity: 0; @include transition; }
.webbox.fade-in { opacity: 1; @include transition; }

.webbox.fade-out + section.fullpattern,
.webbox.fade-out + section + footer,
.webbox:not(:has(.widget)) + section.fullpattern,
.webbox:not(:has(.widget)) + section + footer { opacity: 0; }


a { text-decoration: none; }

html,body { padding: 0; margin: 0; }

* { box-sizing: border-box; }

#root { position: relative; max-width: 100%; }

h1,h2,h3,h4,h5 { padding: 0; margin: 0; line-height: 1.25em; }
ul,li,p { padding: 0; margin: 0; }


.inner { 
  margin: 0 auto; 
}

::selection {
  background: $backgroundO;
}


a.clicker { 
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0; 
  z-index: 420; 
  text-indent: -1000vw;
  overflow: hidden;
}

.swiper{
  height: max-content; 
}

.swiper .controls {
    display: flex;
    justify-content: space-between;
    padding: 0 $space*2;
}


.loader {
  position: fixed; z-index: 99;
  top: 50vh; right: 0; bottom: 0; left: 0; 
  margin: -($baseline) auto 0;
  width: ($baseline*2); height: ($baseline*2); 
  border: ($baseline/3) solid $highlight1;  border-top: ($baseline/3) solid $background;  border-radius: 50%;
  animation: load 1.5s linear infinite;
}

@keyframes load {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}