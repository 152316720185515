section.ctacards {
  .inner { 
    @include desktop { width: $widthm; }
    >h2{
      color: $highlight1;
      margin-bottom: $space*2;
      @include desktop {
          font-size: 32*$ono;
      }
      @include mobile{
        margin-left: $space/2;
      }
  }
  }
  
  ul,li { list-style: none; }
  ul { 
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include desktop {
      margin: 0 -($space/2); 
    }
    @include desktop {
      margin: 0 -($space); 
    }
    
    li {
      @include desktop {
          width: ($widthm/3) + $space/3;
          padding: $space; 
      }
      @include mobile {
        padding: $space/4;
        width: calc(50% - $space/4);
      }
      
      .card { 
        position: relative;
        background: $highlight2;
        border-radius: $radius; 
        @include desktop { 
           padding: $space;
           height: 291*$ono; 
        }
        @include mobile { 
           padding: $space/2;
           height: 220*$ono; 
        }
        //height: (($widthm/3) + $space/3) / 1.25;

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        
        img { 
          @include desktop { height: $ono * 120; margin-bottom: $space; }
          @include mobile { height: $ono * 80; margin-bottom: $space/2; }
        }
        h3 { 
          font-size: $ono * 18;  
          width: 70%;
          text-align: center;
          color: $highlight3;
        }
        
        
       // @include desktop {
              @include mobile {
                //&:hover .hover:has(> .close:hover),
                &:not(.open) .hover {
                  pointer-events: none;
                  opacity: 0; 
                }
              }
              
              &:not(:hover):not(.open) .hover {
                pointer-events: none;
                opacity: 0; 
              }
              
              .hover {
                @include transition; 
                position: absolute;
                @include desktop { 
                  top: 0; right: 0; bottom: 0; left: 0; 
                  .close { display: none; }
                }
                width: 100%; 
                height: 100%; 
                background: $white; 
                border-radius: $radius; 
                padding: $space; 
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: baseline;
      
                & > *:not(:last-child) { margin-bottom: $space/2; }
                
                & > p:has(+ .button) { margin-bottom: 0; }
                .button { margin-top: $space/2; }
                p { flex: 1; }
              }
       // }
       
       @include mobile {
         //&:hover
         &.open {
           position: static;
           .hover { 
             z-index: 999; 
             top: auto; right: $baseline; bottom: auto; left: $baseline; 
             width: auto; height: auto;
             box-shadow: 0 0 $baseline $backgroundO;
             padding: $space*2 $space $space*1.5; 
             .close { 
               content: "\00d7"; display: block;
               position: absolute; top: $baseline; right: $baseline; 
               width: $baseline; height: $baseline; 
               
               background: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18.8px" height="18.8px" viewBox="0 0 18.8 18.8" style="enable-background:new 0 0 18.8 18.8;" xml:space="preserve"><style type="text/css">.st0{fill:%23383838;}</style><path class="st0" d="M10.1,9.4l8.5-8.5c0.2-0.2,0.2-0.5,0-0.7s-0.5-0.2-0.7,0L9.4,8.7L0.9,0.1C0.7,0,0.3,0,0.1,0.1S0,0.7,0.1,0.9l8.5,8.5L0.1,18c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l8.5-8.5l8.5,8.5c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7L10.1,9.4z"/></svg>') no-repeat center center/cover;}
          }
         }
       }
        
      }

    }
    
    
  }
  
}