.widget.newsletter{
    display: flex;
    justify-content: space-between;
    border-radius: 1rem;
    background: $patternbig;
    @include desktop{
      width: $widthm;
      padding: 6rem;
    }
    @include mobile{
      flex-direction: column;
      padding: $space $space/2 $baseline/2;
      margin: $space/2;
    }
    
    h3 { 
      font-size: 42*$ono; 
      color: $highlight3; 
      margin-bottom: $space; max-width: 400*$ono; 
      @include mobile {
        margin-top:0;
        margin-bottom: $baseline/4;
        font-size: $ono*28;
      }
    }
    p { font-size: 24*$ono; font-weight: normal; }
    
    .field-wrapper .input-wrapper .hasBtn {
      @include desktop { width: 25rem; }
    }

    .right{
        .checkbox-label{
          position: relative;
          left: $baseline;
          font-size: $ono*14; 
          
          @include mobile {
            left: $baseline/2; font-size: $ono*13;
            align-self: center;
          }
        }
    }
}