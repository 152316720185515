section.splittext {
  margin: 0 auto; 
  @include desktop {
    width: $widthm;
  }
  
  @include mobile {
    padding: $space $space/2;
  }
  
  &:not(.nobg) {
    div.inner {
      background: $white; color: $black; 
      
      @include desktop { 
        margin: $space*2 auto;
        padding: $space*2;
        border-radius: $radius;
      }
      @include mobile { 
        margin: $space auto;
        padding: $space;
        border-radius: $radius/2;

      }
    }
  }
  
  div.inner { 
    @include desktop {
      display: flex; justify-content: space-between; 

      .textleft,
      .textexpand { width: 50%; }
      .textexpand { padding-top: $baseline/2; }

     .textexpand p:first-child { font-size: $ono*24; line-height: $ono*28; }
      
      h3 { font-size: $ono * 42; }
      textleft p { font-size: $ono * 22; }
    }
    
    @include mobile {
      h3 { font-size: $ono * 28; }
      .textleft p { font-size: $ono * 19; }
    }
    
    color: $white;
    
    .textleft {
      padding-right: $space; 
      
      h3 { 
        color: $highlight1; 
        margin-bottom: $baseline;
      } 
      
     
    }
    .textexpend { 
      padding-left: $space; 
    }
    
    p:not(:last-child) { margin-bottom: $baseline; }
  }

  a.button { margin-top: $baseline; }
  
  
  &.nobg { 
    a.button { background-color: $highlight1; border-color: $highlight1; color: $white;
      &:hover { background-color: $highlight2; border-color: $highlight2; color: $highlight3; }
    }  
  }
  
  &:not(.nobg) {
    a.button { background-color: $highlight1; border-color: $highlight1; color: $white;
      &:hover { background-color: $highlight3; border-color: $highlight3; color: $white; }
    }  
  }
}