.form-textarea{
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 1rem;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none; 
    &.input-error{
        outline: red 2px solid;
    }
    &::placeholder{
        color: #a9a9a9;
    }
}