.card-component {
    position: relative;
    @include transition;
    overflow: hidden;
    height: max-content;

    img {
        width: 100%;
        height: (($widthl/3)-$space)/(16/11);
        object-fit: cover;
    }

    .message {
        padding: $baseline $space $space;
    }

    h5 {
        font-size: $ono * 19;
        font-weight: bolder;
        margin-bottom: $baseline/4;
    }

    time,.emphasisLine {
        display: block;
        text-transform: uppercase;
        font-size: 12*$ono;
        margin-bottom: $baseline;
        font-weight: bold;
    }


    &:hover {
        border-radius: $radius;
        background: $white;
        color: $black;
    }
    .categories{
        h6{
            margin-bottom: $baseline/2;
        }
        .tags{
            display: flex;
            flex-wrap: wrap;
            gap: $space/4;
            &.search{
                margin-top: $space/2;
            }
            .tag{
                border: 1px solid $background;
                border-radius: $radius;
                padding: $space/4;
                color: $background;
            }
        }
        .overflow{
            display: block;
            color: $background;
            margin-top: $baseline/2;
        }
        &.courses{
            margin-top: $space/2;
        }
    }
}