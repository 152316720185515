input:not([type='checkbox']):not([type='radio']):not([type='search']){
    width: 100%;
    height: 40*$ono;
    padding: 12*$ono;
    border-radius: $radius*2;
    border: none;
    outline: none;
    &:focus{
        outline: darkgreen 2px solid;
    }
    &.input-error{
        outline: red 2px solid;
    }
    &::placeholder{
        color: #a9a9a9;
    }
}
.field-wrapper{
    position: relative;
    width: 100%;

    .input-wrapper{
        position: relative;
        .hasBtn{
            height: 66*$ono !important;
        }

        & button{
            position: absolute;
            height: 80%;
            top: 50%;
            right: 5*$ono;
            bottom: 50%;
            transform: translate(0,-50%);
            border-radius: $radius*2;
        }
    }
}