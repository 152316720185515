section.video {
  position: relative;
  @include desktop { 
    width: $widthm;
    height: calc($widthm/(16/9));
  }
  @include mobile { 
    padding-top: calc(100%/(16/9));
    margin: 0 $space/2;
    //width: 100%; //height: calc(100vw/(16/9));
  }

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  button {
    position: absolute; z-index: 51;
    left: 0; right: 0; top: 0; bottom: 0; margin: auto;
    
    @include desktop { 
      width: 105*$ono;
      height: 105*$ono;
    }
    @include mobile { 
      width: 60*$ono;
      height: 60*$ono;
      padding: 0; 
    }
        
    text-indent: -100vh; overflow: hidden;
    //background: transparent url('//global.scotch.wa.edu.au/assets/svg/play.svg') center center/cover;
    border: 0;
    @include transition;
    cursor: pointer;
    
    &:hover { opacity: .875; }
  }

  img {
    position: absolute; z-index: 50;
    width: 100%; height: 100%;
    top: 0; left: 0;
    object-fit: cover;
  }
}


body[site="global"] section.video button { background: transparent url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="105px" height="105px" viewBox="0 0 105 105" style="enable-background:new 0 0 105 105;" xml:space="preserve"><style type="text/css">.st0{fill:%23FFFFFF;}.st1{fill:%23444f22;}</style><circle class="st0" cx="52.5" cy="52.5" r="52.5"/><path class="st1" d="M66,50.8c1.3,0.8,1.3,2.7,0,3.5L45,66.4c-1.3,0.8-3-0.2-3-1.7V40.4c0-1.5,1.7-2.5,3-1.7L66,50.8z"/></svg>') center center/cover; } 
body[site="accelerate"] section.video button { background: transparent url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="105px" height="105px" viewBox="0 0 105 105" style="enable-background:new 0 0 105 105;" xml:space="preserve"><style type="text/css">.st0{fill:%23FFFFFF;}.st1{fill:%231a464e;}</style><circle class="st0" cx="52.5" cy="52.5" r="52.5"/><path class="st1" d="M66,50.8c1.3,0.8,1.3,2.7,0,3.5L45,66.4c-1.3,0.8-3-0.2-3-1.7V40.4c0-1.5,1.7-2.5,3-1.7L66,50.8z"/></svg>') center center/cover; } 
body[site="connect"] section.video button { background: transparent url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="105px" height="105px" viewBox="0 0 105 105" style="enable-background:new 0 0 105 105;" xml:space="preserve"><style type="text/css">.st0{fill:%23FFFFFF;}.st1{fill:%23832818;}</style><circle class="st0" cx="52.5" cy="52.5" r="52.5"/><path class="st1" d="M66,50.8c1.3,0.8,1.3,2.7,0,3.5L45,66.4c-1.3,0.8-3-0.2-3-1.7V40.4c0-1.5,1.7-2.5,3-1.7L66,50.8z"/></svg>') center center/cover; } 
body[site="thrive"] section.video button { background: transparent url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="105px" height="105px" viewBox="0 0 105 105" style="enable-background:new 0 0 105 105;" xml:space="preserve"><style type="text/css">.st0{fill:%23FFFFFF;}.st1{fill:%234d1825;}</style><circle class="st0" cx="52.5" cy="52.5" r="52.5"/><path class="st1" d="M66,50.8c1.3,0.8,1.3,2.7,0,3.5L45,66.4c-1.3,0.8-3-0.2-3-1.7V40.4c0-1.5,1.7-2.5,3-1.7L66,50.8z"/></svg>') center center/cover; } 





