.select-wrapper{
    .form-label{
        display: inline-block;
        margin-bottom: $space/2;
    }
    .form-select{
        width: 100%;
        &.single{
            height: 40*$ono;
            background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-position: 100% center;
            background-size: 25*$ono;
        }
        &.multiple{
            option{
                padding: $space/4;
                &:not(:first-child){
                    margin-top: $ono*4;
                }
                &:checked{
                    border-radius: $radius;
                }
            }
        }
        appearance: none !important;
        -webkit-appearance:none !important;
        outline: none !important;
        border: none;
        border-radius: $radius;
        padding: $space/4;
        color: #a9a9a9;
        background-color: #fff;
        border-right: 16*$ono solid transparent;     
        &.input-error{
            outline: red 2px solid;
        }
    }
}