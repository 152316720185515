/*
////////////////// LOCAL FONTS
      // Heading
      @font-face {
          font-family: 'heading';
          src: url('../fonts/heading-extrabold-webfont.woff2') format('woff2'),
               url('../fonts/heading-extrabold-webfont.woff') format('woff');
          font-weight: bold;
          font-style: normal;
      }
      
      @font-face {
          font-family: 'heading';
          src: url('../fonts/heading-regular-webfont.woff2') format('woff2'),
               url('../fonts/heading-regular-webfont.woff') format('woff');
          font-weight: normal;
          font-style: normal;
      }
      
      
      // Base font
      
      @font-face {
          font-family: 'base';
          src: url('../fonts/base-bold-webfont.woff2') format('woff2'),
               url('../fonts/base-bold-webfont.woff') format('woff');
          font-weight: bold;
          font-style: normal;
      }
      
      @font-face {
          font-family: 'base';
          src: url('../fonts/base-medium-webfont.woff2') format('woff2'),
               url('../fonts/base-medium-webfont.woff') format('woff');
          font-weight: 400;
          font-style: normal;
      }
      @font-face {
          font-family: 'base2';
          src: url('../fonts/base-medium-webfont.woff2') format('woff2'),
               url('../fonts/base-medium-webfont.woff') format('woff');
          font-weight: normal;
          font-style: normal;
      }
      
      @font-face {
          font-family: 'base';
          src: url('../fonts/base-regular-webfont.woff2') format('woff2'),
               url('../fonts/base-regular-webfont.woff') format('woff');
          font-weight: normal;
          font-style: normal;
      }




////////////////// STAGING FONTS
      
*/

      // Heading
      @font-face {
          font-family: 'heading';
          src: url('//global.scotch.wa.edu.au/assets/fonts/heading-extrabold-webfont.woff2') format('woff2'),
               url('//global.scotch.wa.edu.au/assets/fonts/heading-extrabold-webfont.woff') format('woff');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'heading';
          src: url('//global.scotch.wa.edu.au/assets/fonts/heading-regular-webfont.woff2') format('woff2'),
               url('//global.scotch.wa.edu.au/assets/fonts/heading-regular-webfont.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
      }
      
      
      // Base font
      
      @font-face {
          font-family: 'base';
          src: url('//global.scotch.wa.edu.au/assets/fonts/base-bold-webfont.woff2') format('woff2'),
               url('//global.scotch.wa.edu.au/assets/fonts/base-bold-webfont.woff') format('woff');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'base';
          src: url('//global.scotch.wa.edu.au/assets/fonts/base-medium-webfont.woff2') format('woff2'),
               url('//global.scotch.wa.edu.au/assets/fonts/base-medium-webfont.woff') format('woff');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
      }
      @font-face {
          font-family: 'base2';
          src: url('//global.scotch.wa.edu.au/assets/fonts/base-medium-webfont.woff2') format('woff2'),
               url('//global.scotch.wa.edu.au/assets/fonts/base-medium-webfont.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'base';
          src: url('//global.scotch.wa.edu.au/assets/fonts/base-regular-webfont.woff2') format('woff2'),
               url('//global.scotch.wa.edu.au/assets/fonts/base-regular-webfont.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
      }

////

body { 
  font-family: 'base', sans-serif;
}

h1 {
  font-family: 'heading', sans-serif;
  text-transform: uppercase;
}

h2,h3,h4 { 
  font-family: 'heading', sans-serif;
  font-weight: normal;
}

h5 { 
  font-family: 'base', sans-serif;
  font-weight: bolder;
}


input, textarea,select { 
  font-family: 'base', sans-serif;
  font-size: $ono * 16; 
}