.field-wrapper.file{
    input{
        display: none;
    }
    button{
        width: 100%;
        &[disabled]{
            opacity: .5;
            &:hover{
                pointer-events: none;
            }
        }
    }
    ul.files{
        margin-top: $space/2;
        list-style: none;
        li{
            text-decoration: underline;
            &:hover{
                cursor: pointer;
            }
            &:not(:first-child){
                margin-top: $space/4;
            }
        }
    }
}