.widget.datalist{
  .inner{
    background-color: $highlight1;
    border-radius: $radius;
    
    @include desktop {
      max-width: 100%;
      width: $widthl;
      padding: $space*2 $space;
    }
    
    @include mobile {
      margin: $space $space/2;
      padding: $space*2 0; 
    }
  }
  
  h3 { 
    @include desktop {
      font-size: $ono*32; 
    }
    @include mobile { 
      font-size: $ono*26;
    }
    color: $white;
    padding: 0 $baseline;
  }
  
  ul{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include desktop {
      margin: $space 0 0;
    }
    @include mobile {
      padding: $baseline;
      justify-content: space-evenly;
      margin: ($space/2) 0 0;
    }
  }
  
  li {
    padding: baseline;
    border-radius: $radius;
    @include transition;
    
    @include desktop {
      margin: $baseline;
      
      border: $ono solid $highlight2;
      color: $white;
      
      a { padding: $space/2; }
    
      &:hover{
        background-color: $white;
        color: $background;
      }
    }
    
    @include mobile {
      width: 100%;
      margin: $space/2;
      padding: $space/2;
      
      background-color: $white;
      color: $background;
      border: $ono solid $highlight2;
      
      a { padding: $space/4; }
      
      &:hover{
        background-color: $highlight1;
        color: $white;
      }
    }
    
    strong { display: block; margin-bottom: $baseline/8; }
    
    a {
      @include desktop { width: $ono*380; }
      display: block;
      
      color: inherit;
    }
  }
  
}