.widget.teamlist {
    .inner {
        background: $highlight2;
        border-radius: $radius;
        @include desktop{
            width: $widthl;
            padding: $space*2;
        }
        @include mobile{
            padding: $space;
            margin: 0 $space/2;
        }
    }
    .top{
        display: flex;
        margin: $space 0 0;
        justify-content: space-between;
        @include mobile{
            flex-direction: column;
            margin: 0;
            gap: $space;
        }
    }
    h3 {
        color: $background;
        margin-bottom: 0;
        font-size: 32*$ono;
    }

    ul {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        @include mobile{
            display: flex;
            flex-direction: column;
        }
        margin: $space 0;
        gap: $space*1.5;
    }
}