.platforms {
  position: fixed; right: 0; left: 0; width: max-content; margin: 0 auto; z-index: 4000; 
  
  background: $white; 
  font-size: $ono*14;
  
  @include desktop { 
    bottom: $baseline; 
    padding: $baseline/1.5; 
    border-radius: $baseline*2;
    box-shadow: 0 $baseline $baseline*2 rgba(0,0,0,.125);
  }
  
  @include mobile {
    bottom: $baseline/2; 
    padding: $baseline/2; 
    border-radius: $baseline*2;
    box-shadow: 0 $baseline/2 $baseline rgba(0,0,0,.125);
  }

  &, a { @include transition; }
  
  a { 
    color: $black; 
    display: inline-block; 

    border: $ono*1.5 solid $white;
    border-radius: $baseline;   
    font-family: 'base2', sans-serif; font-weight: 100;
    &:hover { font-weight: bold; }
    
    @include desktop { 
      padding: $baseline/1.5; 
    }
    
    @include mobile { 
      padding: $baseline/3 $baseline/1.5; 
    }
  }
}

body[site="global"].scrolling .platforms:not(:hover) a:not(:first-child),
body[site="connect"].scrolling .platforms:not(:hover) a:not([href*="connect"]),
body[site="accelerate"].scrolling .platforms:not(:hover) a:not([href*="accelerate"]),
body[site="thrive"].scrolling .platforms:not(:hover) a:not([href*="thrive"]) { 
  font-size: 0px; color: $white; padding: 0; opacity: 0; border: 0; 
}


body[site="global"]:not(.scrolling) .platforms a:first-child,
body[site="connect"]:not(.scrolling) .platforms a[href*="connect"],
body[site="accelerate"]:not(.scrolling) .platforms a[href*="accelerate"],
body[site="thrive"]:not(.scrolling) .platforms a[href*="thrive"],
body[site="global"].scrolling .platforms:hover a:first-child,
body[site="connect"].scrolling .platforms:hover a[href*="connect"],
body[site="accelerate"].scrolling .platforms:hover a[href*="accelerate"],
body[site="thrive"].scrolling .platforms:hover a[href*="thrive"] { 
  font-weight: bold; 
  border: $ono*1.5 solid $highlight1;   
  border-radius: $baseline;
  font-weight: bold;
  color: $background;
  //pointer-events: none;
}


body[site="global"].scrolling .platforms:not(:hover) a:first-child,
body[site="connect"].scrolling .platforms:not(:hover) a[href*="connect"],
body[site="accelerate"].scrolling .platforms:not(:hover) a[href*="accelerate"],
body[site="thrive"].scrolling .platforms:not(:hover) a[href*="thrive"] { 
  font-weight: bold; 
  border: $ono*1.5 solid $white;   
  border-radius: $baseline;
}