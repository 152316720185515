section.newsslider {
  &, .inner { 
    position: relative; 
  } 
  
  &:not(.nobg) .inner { 
    @include desktop { width: $widthl; }
    @include mobile { margin: $space/2; }
    background: $highlight2;
    border-radius: $radius;
  }
  
  .inner { 
    @include desktop { padding: $space 0 $space*5; }
    @include mobile { padding: $space 0 $space/2; }
    overflow: hidden;
  }

  h3 {
    @include desktop { padding: $space $space*2 0; }
    @include mobile { padding: 0 $space/2; margin-top: $space/4; }
    margin-bottom: 0;
    font-size: 32*$ono;
    color: $highlight3
  }
  
  .controls {
    position: absolute;
    bottom: -($space*4.5); left: $space; right: $space;
    
    display: flex;
    justify-content: space-between;
  }

  .swiper{
    overflow: initial !important;
    margin: $space*2; 
    height: max-content; 
  }
  
  .newslist {
    @include mobile {
      padding: $space/2; 
    }
    
    li { list-style: none; }
  }
  
  .item { 
    position: relative;
    @include transition;
    @include desktop { width: ($widthl/3)-$space; }
    @include mobile { width: 100%; margin-top: $space }
    overflow: hidden;   
    height: max-content; 
      
    img { 
      width: 100%; 
      height: (($widthl/3)-$space)/(16/11); 
      object-fit: cover;
    }
    
    .message {
      padding: $baseline $space $space;
    }
    
    h5 { 
      font-size: $ono * 19; 
      font-weight: bolder; 
      margin-bottom: $baseline/4;
    }
    
    time { 
      display: block;
      text-transform: uppercase; 
      font-size: 12*$ono; 
      margin-bottom: $baseline;
      font-weight: bold;
    }
    
    
    &:hover {
      border-radius: $radius;
      background: $white; color: $black;
    }
  }
    
    
  &.nobg {
    h3, .controls { width: $widthl; margin: 0 auto; }
    
    
    .inner {
      width: 100%; 
      
      h3 { color: $white; }
      
      &::before,
      &::after { 
        content: ''; 
        display: block; 
        z-index: 10;
        position: absolute;
        top: 0; bottom: 0; 
        width: $space; height: 100%; 
        pointer-events: none;
      }
      
      &::before { 
        left: 0; 
        background: linear-gradient(90deg, $background 0%, $backgroundO0 100%);
      }
      &::after { 
        right: 0; 
        background: linear-gradient(270deg, $background 0%, $backgroundO0 100%);
      }
      
      .item { 
        color: $white;
        &:hover{
          color: black;
        }
       }
    }
  }
    

}
